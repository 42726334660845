import React from 'react'
import { Container, Grid, List, ListItem, ListItemText, Button, Table, Typography, ImageListItem } from '@material-ui/core'

const StepEight = ({ prevStep, nextStep, values }) => {

	const { rbemail, rbtitle, rbdepartment, rbfirstname, rblastname, rbmobile, cemail, ctitle, cdepartment, 
			cfirstname, clastname, cmobile, clogo, bankers, ispieview, issmartview, isstatsview,
			isrecomendedview, isaccountview, issmartsrcvview, issmartartview, statementtype,
			pname,isinternalview, isexternalview, graphItems } = values;

	const Continue = e => {
		e.preventDefault();
		nextStep();
	}

	const Previous = e => {
		e.preventDefault();
		prevStep();
	}
	
	const renderInternalView = <h6>{isinternalview ? <p>Internal view presentation</p> : ''}</h6>;
	 
	const renderExternalView =	<h6>{isexternalview ? <p>External view presentation</p> : ''}</h6>;
	
	const renderPieView = <h2>{ispieview ? <img
								  src="/PieChartSample.JPG"								  
								  alt="Church"
								  loading="lazy"
								  width="200"
								  height="200"
							  /> : ''}</h2>;
							  
    const renderSmartView = <h2>{issmartview ? <img
								  src="/SmartChartSample.JPG"								  
								  alt="Church"
								  loading="lazy"
								  width="200"
								  height="200"
							  /> : ''}</h2>;	
							  
    const renderStatstView = <h2>{isstatsview ? <img
								  src="/SummaryViewSample.JPG"								  
								  alt="Church"
								  loading="lazy"
								  width="200"
								  height="200"
							  /> : ''}</h2>;

	const renderRecommendView = <h2>{isrecomendedview ? <img
								src="/RecommendationViewSample.JPG"
								alt="Church"
								loading="lazy"
								width="200"
								height="200"
							/> : ''}</h2>;

	const renderSmartProtectView = <h2>{issmartartview ? <img
								src="/SmartProtectViewSample.JPG"
								alt="Church"
								loading="lazy"
								width="200"
								height="200"
							/> : ''}</h2>;

	const renderSmartServiceView = <h2>{issmartsrcvview ? <img
								src="/SmartServiceViewSample.JPG"
								alt="Church"
								loading="lazy"
								width="200"
								height="200"
							/> : ''}</h2>;

	const renderAccountView = <h2>{isaccountview ? <img
								src="/AccountViewSample.JPG"
								alt="Church"
								loading="lazy"
								width="200"
								height="200"
							/> : ''}</h2>;

	const groupList = Array.from(bankers).map((banker, i) => {	 
		return (
			<tr key={i}>
			<td>{banker.firstName} {banker.lastName}</td>     
			<td>{banker.email}</td> 
			<td>{banker.title}</td> 
			<td>{banker.department}</td> 
			</tr>
		)    
    });
    	
	return (		
		<>
			<div id="content" className="p-2">
			<div className="form-submission">
				<div className="col-lg-12">
					<div className="card-header mb-4">
						<h4 className="card-title">Presentation Name</h4>
						<h3>Treasury and Liquidity Management Presentation</h3>
					</div>
					<div className="row">
						<div className="col-lg-12">
						<h4 className="underline">Type of presentation selected:</h4>
						<div className="row">
							<div className="col-md-8">
								<div className="row liquidity">
									<div className="col-md-4">
										<div className="form-group">
											<h6>Banker First Name<br />
												{rbfirstname}
											</h6>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<p>Client First Name</p>
											{cfirstname}
										</div>
									</div>
								</div>
								<div className="row liquidity">
									<div className="col-md-4">
										<div className="form-group">
											<h6>Banker Last Name<br />
												{rblastname}
											</h6>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<p>Client Last Name</p>
											{clastname}
										</div>
									</div>
								</div>
								<div className="row liquidity">
									<div className="col-md-4">
										<div className="form-group">
											<h6>Banker Email<br />
												{rbemail}
											</h6>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<p>Client Email</p>
											{cemail}
										</div>
									</div>
								</div>
								<div className="row liquidity">
									<div className="col-md-4">
										<div className="form-group">
											<h6>Banker Title<br />
												{rbtitle} 
											</h6>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<p>Client Title</p>
											{ctitle}
										</div>
									</div>
								</div>
								<div className="row liquidity">
									<div className="col-md-4">
										<div className="form-group">
											<h6>Banker Department<br />
												{rbdepartment}
											</h6>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<p>Client Department</p>
											{cdepartment}
										</div>
									</div>
								</div>
								<div className="row liquidity">
									<div className="col-md-4">
										<div className="form-group">
											<h6>Banker Mobile<br />
												{rbmobile}
											</h6>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<p>Client Mobile</p>
											{cmobile}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								{
									clogo ?
										<img src={clogo} className="img-fluid" />
										:
										<img src="/assets/images/no-image.png" className="img-fluid" /> 
								}							
								{/* <img src="/assets/images/no-image.png" className="img-fluid" /> */}								
							</div>
						</div>
						<h4 className="underline-double mt-3">Types of views selected:</h4>
						<Grid container spacing={2}>
						{
							graphItems.map(graph => {
								if(graph.isSelected) {
									return <Grid item xs={12} sm={4} key={graph.alt}>
												<h2>
													<img
														src={`/${graph.image}`}
														alt={graph.alt}
														loading="lazy"
														width="200"
														height="200"
													/>
												</h2>
											</Grid>
								} else {
									return null;
								}
							})
						}
						</Grid>	
						<hr/>					
						<h4 className="mb-3">Bankers Selected:</h4>
						<table className="table table-bordered">
							<thead>
								<tr>
									<th scope="col">Name</th>
									<th scope="col">Email</th>
									<th scope="col">Title</th>
									<th scope="col">Department</th>
								</tr>
							</thead>								
							<tbody>
								{ groupList } 
							</tbody>
						</table>
						<p className="p-button-holder"> 
							<input type="button" className="btn btn-success-custom previous mr-3 mt-3 text-center" value="Previous" onClick={ Previous } />
                            <input type="button" className="btn btn-success-custom next mt-3 text-center" value="Confirm & Continue" onClick={ Continue } />            
						</p>
						</div>
					</div>
				</div>
			</div>
			</div>		
		</>
	)
}

export default StepEight