import React, { useState, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const StepFour = ({ prevStep, nextStep, handleChange, values }) => {
  
  const Continue = e => {
    e.preventDefault();

    /*
    if (values.cfirstname.trim() === "") {
      NotificationManager.error("Please enter a first name", "Error");
    } else if (values.clastname.trim() === "") {
      NotificationManager.error("Please enter a last name", "Error");
    } else if (values.ctitle.trim() === "") {
      NotificationManager.error("Please enter a title", "Error");
    } else {
        nextStep();
    }
    */

    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  //console.log("Updated state 4:", values); // Log the updated state
  
  return (
    <>
      <div id="content" className="p-2">
        <div className="form-submission">
          <div className="col-lg-12">
            <div className="card-header mb-4">
              <h4 className="card-title">Generate Presentation</h4>
              <h3>Client Details</h3>
            </div>        
            <div className="row">
              <div className="col-lg-12">            
                <div className="php-email-form">
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="First Name" onChange={handleChange('cfirstname')} value={values.cfirstname} />
                      </div>
                      <div className="col-md-6 ">
                        <input type="text" className="form-control" placeholder="Last Name" onChange={handleChange('clastname')} value={values.clastname} />
                      </div>
                      <div className="col-md-6 ">
                        <input type="text" className="form-control" placeholder="Title" onChange={handleChange('ctitle')} value={values.ctitle} autoComplete="Title"  />
                      </div>                
                      <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="Department" onChange={handleChange('cdepartment')} value={values.cdepartment} autoComplete="Customer Department" />
                      </div>
                      <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="Email" onChange={handleChange('cemail')} value={values.cemail} autoComplete="Email" />
                      </div>
                      <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="Mobile" onChange={handleChange('cmobile')} value={values.cmobile} autoComplete="Mobile" />
                      </div>                
                      <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="Client Logo URL" onChange={handleChange('clogourl')} value={values.clogo} autoComplete="Cleint Logo" />
                        <p className="mb-1 mt-1">Or</p>
                        <label className='btn btn-info d-block'>
                          Upload Client Logo
                          <input type="file" className="form-control" accept='image/jpg, image/jpeg, image/png' onChange={handleChange('clogo')} style={{display:'none'}}/>
                        </label>
                        {values.logoPreview && <img src={values.logoPreview} alt="preview" style={{width:'135px'}} />}
                      </div>
                      <p className="p-button-holder">
                        <input type="button" className="btn btn-success-custom previous mr-3" value="Previous" onClick={ Previous } />
                        <input type="button" className="btn btn-success-custom next ml-3" value="Next" onClick={ Continue } /> 
                      </p>                     
                    </div>
                </div>
                <img src="/assets/images/Introslide (1).jpg" alt="" title="" className="mt-3 img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NotificationContainer id="stepFourError" />    
    </>
  )
}

export default StepFour