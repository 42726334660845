import React from "react"
const PresentationType = ({values, handleChange}) => {

    return <>
         <div className="row">
            <div className='col-12'>
                <h4>Presentation Type</h4>
            </div>
            <div className="col-12">
                <label className="d-block cursor-pointer" for="external"><input type="radio" id="external" name="presentationType" value={'external'} checked={values.presentationType === 'external'} onChange={handleChange('presentationType')} />External Presentation</label>
                <label className="d-block cursor-pointer" for="virtual"><input type="radio" id="virtual" name="presentationType" value={'virtual'} checked={values.presentationType === 'virtual'} onChange={handleChange('presentationType')} />  Virtual Presentation</label>
            </div>
            <div className="col-12 mt-2">
                <p>
                    <span>External Presentation: -</span> This will provide the option to generate the presentation from the provided data.
                </p>
                <p>
                    <span>Virtual Presentation: -</span> This will provide the option to virtually view the ratio in the webpage from the provided data.
                </p>
            </div>
        </div>
    </>

};
export default PresentationType;