import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { BASE_API_URL } from "../../config";
import {Collapse} from 'bootstrap';

const Sidebar = (props) => {
    //const [sidebarOpen, setSidebarOpen] = useState(true);
    const [activeItem, setActiveItem] = useState('');
    const [activeChildItem, setActiveChildItem] = useState('');
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);  
    const [cookies] = useCookies(['XSRF-TOKEN']);  
    const [loading, setLoading] = useState(false);  
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(undefined);
    const [auth, setAuth] = useState(false);
    const [isAdminUser, setAdminUser] = useState(false);
    const [isRMUser, setRMUser] = useState(false);
    const [isEveryOneElse, setEveryOneElse] = useState(false);
    const [count, setCount] = useState(0);
    let dataFetchedRef = useRef(false);
    const navBarRef = useRef(null);
    let authenticatedUSer = null;
    const history = useNavigate();
    
    useEffect(() => {
        setLoading(true); 
        if (dataFetchedRef.current) {
            return;
        } else {
            dataFetchedRef.current = true;
            setCount(count + 1);
        }
        fetch(`${BASE_API_URL}/api/user`, { credentials: 'include'})
        .then(response => response.text())
        .then(body => {
            if (body === '') 
            {
                setAuthenticated(false);
                setAuth(false);
                console.log("User not authenticated, redirecting the user to homepage");
                history('/');
            } 
            else 
            {		          
                setUser(JSON.parse(body));
                authenticatedUSer = JSON.parse(body); // console.log(authenticatedUSer, "USERSSS");
                setAuthenticated(true);
                if(authenticatedUSer.groups.includes('admins')){						
                    setAdminUser(true);
                }
                if(authenticatedUSer.groups.includes('rusers')){						
                    setRMUser(true);
                }
                if(authenticatedUSer.groups.includes('Everyone')){						
                    setEveryOneElse(true);
                }
                setAuth(true);
            }
            setLoading(false); 
            dataFetchedRef.current = true;                
        });
    }, []);
    
    useEffect(() => {
        const currentPath = window.location.pathname;
        setActiveItem(getActiveItem(currentPath));
        setActiveChildItem(getActiveChildItem(currentPath));
        
    }, []);
    
    const getActiveItem = (path) => {
        if (path === '/generate/powerpoint') {
            return 'generatePowerpoint';
        } else if (path === '/run/history') {
            return 'runHistory';
        } else if (path.includes('/banker/') === true) {
            return 'manageBankers';
        } else if ((path.includes('/tmservice') === true) || (path.includes('/recommend/') === true)) {
            return 'manageRecommendations';
        } else if (path.includes('/pmapping') === true) {
            return 'parserConfiguration';
        } else if (path.includes('/financial-analysis/working-capital') || path.includes('/financial-analysis/liquidity-ratio')) {
            return "financialAnalysis";
        }
        return '';
    };
    
    const getActiveChildItem = path => {
        if (path.includes('/financial-analysis/working-capital')) {
            return "workingCapital";
        } else if(path.includes('/financial-analysis/liquidity-ratio')) {
            return "liquidityRatio";
        }
        return '';
    }

    const handleItemClick = (item, e) => {
        const target = e.target;
        const nextEl = target.nextElementSibling;
        const parentEl = target.parentElement;
        if(nextEl) {
            e.preventDefault();
            const collapse = new Collapse(nextEl);
            if(nextEl.classList.contains('show')) {
                collapse.hide();
            } else {
                collapse.show();
                const openSubMenu = parentEl.parentElement.querySelector('.submenu.show');
                console.log(openSubMenu);
                if(openSubMenu) {
                    new Collapse(openSubMenu);
                }
            }
        }
        setActiveItem(item);
    };

    // const handleSidebarToggle = () => {
    //     setSidebarOpen(!sidebarOpen);
    // };    

    return (
        <React.Fragment>
            {/* <nav id="sidebar" style={{ display: props.sidebarOpen ? 'block' : 'none' }}> */}

            <nav className="sidebar card py-2 mb-4" id="sidebar" ref={navBarRef}>
                <ul className="nav flex-column" id="nav_accordion">
                     {isAdminUser && <li className={"nav-item " + (activeItem === 'generatePowerpoint' ? 'active' : '')}>
                        <a className="nav-link" href="/generate/powerpoint" onClick={(e) => handleItemClick('generatePowerpoint', e)}><i className="fa-solid fa-file-powerpoint"></i> Create Presentation</a>
                    </li>}
                    {isEveryOneElse && <li className={"nav-item " + (activeItem === 'runHistory' ? 'active' : '')}>
                        <a className="nav-link" href="/run/history" onClick={(e) => handleItemClick('runHistory', e)}><i className="fa-solid fa-rectangle-history"></i> Run History</a>
                    </li>}
                    {isAdminUser && <li className={"nav-item " + (activeItem === 'manageBankers' ? 'active' : '')}>
                        <a className="nav-link" href="/banker/list" onClick={(e) => handleItemClick('manageBankers', e)}><i className="fa-solid fa-building-columns"></i>Manage Bankers</a>
                    </li>}
                    {isAdminUser && <li className={"nav-item " + (activeItem === 'manageRecommendations' ? 'active' : '')}>
                        <a className="nav-link" href="/bankers/tmservice" onClick={(e) => handleItemClick('manageRecommendations', e)}><i className="fa-solid fa-thumbs-up"></i> Manage Recommendations</a>
                    </li>}
                   {isAdminUser &&  <li className={"nav-item " + (activeItem === 'parserConfiguration' ? 'active' : '')}>
                        <a className="nav-link" href="/bankers/pmapping" onClick={(e) => handleItemClick('parserConfiguration', e)}><i className="fa-solid fa-gear"></i>Parser Configuration</a>
                    </li>}
                    <li className={"nav-item has-submenu "  + (activeItem === 'financialAnalysis' ? 'active' : '')}>
                        <a className="nav-link" href="#" onClick={(e) => handleItemClick('financialAnalysis', e)}><i className="fa-solid fa-chart-line"></i> Financial Analysis  </a>
                        <ul className="submenu collapse">
                            <li className={activeChildItem === "workingCapital" ? 'active' : null}>
                                <Link className={"nav-link"} to="/financial-analysis/working-capital" onClick={() => setActiveChildItem("workingCapital")}>
                                    <i className="fa-solid fa-money-bill-trend-up"></i> Working Capital 
                                </Link>
                            </li>
                            {/* <li className={activeChildItem === "liquidityRatio" ? 'active' : null}>
                                <Link className="nav-link" to="/financial-analysis/liquidity-ratio" onClick={() => setActiveChildItem("liquidityRatio")}>
                                    <i className="fa-solid fa-percent"></i> Liquidity Ratio 
                                </Link>
                            </li> */}
                        </ul>
                    </li>
                    {/* <li class="nav-item has-submenu">
                        <a class="nav-link" href="#"> More menus  </a>
                        <ul class="submenu collapse">
                            <li><a class="nav-link" href="#">Submenu item 4 </a></li>
                            <li><a class="nav-link" href="#">Submenu item 5 </a></li>
                            <li><a class="nav-link" href="#">Submenu item 6 </a></li>
                            <li><a class="nav-link" href="#">Submenu item 7 </a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#"> Something </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#"> Other link </a>
                    </li> */}
                </ul>
            </nav>


            {/* <nav id="sidebar" className={props.sidebarOpen ? '' : 'active'}>
                <div className="p-4">
                    <ul className="list-unstyled components">
                        { isAdminUser ? <li className={activeItem === 'generatePowerpoint' ? 'active' : ''}> <a href="/generate/powerpoint" 
                        onClick={() => handleItemClick('generatePowerpoint')}><i className="fa-solid fa-file-powerpoint"></i> Generate Powerpoint</a> </li> : null }
                        { isEveryOneElse ? <li className={activeItem === 'runHistory' ? 'active' : ''}> <a href="/run/history" onClick={() => handleItemClick('runHistory')}><i className="fa-solid fa-rectangle-history"></i> Run History </a> </li> : null }
                        { isAdminUser ? <li className={activeItem === 'manageBankers' ? 'active' : ''}> <a href="/banker/list" onClick={() => handleItemClick('manageBankers')}><i className="fa-solid fa-building-columns"></i>Manage Bankers</a> </li> : null }
                        { isAdminUser ? <li className={activeItem === 'manageRecommendations' ? 'active' : ''}> <a href="/bankers/tmservice" onClick={() => handleItemClick('manageRecommendations')}><i className="fa-solid fa-thumbs-up"></i> Manage Recommendations</a> </li> : null }
                        { isAdminUser ? <li className={activeItem === 'parserConfiguration' ? 'active' : ''}> <a href="/bankers/pmapping" onClick={() => handleItemClick('parserConfiguration')}><i className="fa-solid fa-gear"></i>Parser Configuration</a> </li> : null }
                        { isAdminUser ? <li className={activeItem === 'parserConfiguration' ? 'active' : ''}> <a href='javascript:void(0)' onClick={() => handleItemClick('financialAnalysis')}><i className="fa-solid fa-gear"></i>Financial Analysis</a> </li> : null }
                    </ul>
                </div>
            </nav>           */}
        </React.Fragment> 
    );
};

export default Sidebar;
