
export const CommonService = {
    // Define the common function for the whole projects
    isJsonParsable(str) {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    },
    roundNum(num){
        return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    formatToUSMoney(number) {
        const formatter = new Intl.NumberFormat("en-US", {
            style: 'currency',
            currency: "USD",
        });
        return formatter.format(number);
    }
};