import React, { useState } from "react";
import ImageView from '../../ImageView';

const RenderingTemplate = ({ handleChange, values }) => {
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState('');
  const onClose = () => {
    setOpen(false);
  }

  const openImage = src => {
    setSource(src);
    setOpen(true);
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4>Choose Display Option</h4>
        </div>
        <div className="col-lg-12">
          <div className="row gy-4">
            {values.inventory && <div className="col-md-6 rendering">
              <div className="form-group form-submission">
                <label htmlFor="WorkingCapitalMapINV">
                  Working Capital Map With Inventory
                  <input
                    type="checkbox"
                    id="WorkingCapitalMapINV"
                    checked={values.workingCapitalWithInventory}
                    onChange={handleChange("workingCapitalWithInventory")}
                  />
                  </label>
                  <img
                    htmlFor="WorkingCapitalMapINV"
                    src="/assets/images/WorkingCapitalMapWithInventory.jpg"
                    className="img-fluid"
                    alt="WorkingCapitalMapINV"
                    onClick={() => openImage("/assets/images/WorkingCapitalMapWithInventory.jpg")}
                  />
              </div>
            </div>}
            <div className="col-md-6 rendering">
              <div className="form-group form-submission">
                <label htmlFor="WorkingCapitalMap" className="cursor-pointer">
                  <input
                    type="checkbox"
                    id="WorkingCapitalMap"
                    checked={values.workingCapitalWithoutInventory}
                    onChange={handleChange("workingCapitalWithoutInventory")}
                  />
                  Working Capital Map Without Inventory
                  </label>
                  <img
                    src="/assets/images/WorkingCapitalMapWithoutInventory.JPG"
                    className="img-fluid"
                    alt="WorkingCapitalMapWithoutInventory"
                    onClick={() => openImage("/assets/images/WorkingCapitalMapWithoutInventory.JPG")}
                  />
              </div>
            </div>
            {values.inventory && <div className="col-md-6 rendering">
              <div className="form-group form-submission">
                <label
                  htmlFor="WorkingCapitalCCCINV"
                  className="cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="WorkingCapitalCCCINV"
                    checked={values.workingCapitalCCCDateWithInventory}
                    onChange={handleChange(
                      "workingCapitalCCCDateWithInventory"
                    )}
                  />
                  Working Capital CCC Date With Inventory
                  </label>
                  <img
                    src="/assets/images/WorkingCapitalCCCDateWithInventory.jpg"
                    className="img-fluid"
                    alt="WorkingCapitalCCCDateWithInventory"
                    onClick={() => openImage("/assets/images/WorkingCapitalCCCDateWithInventory.jpg")}
                  />
              </div>
            </div>}
            <div className="col-md-6 rendering">
              <div className="form-group form-submission">
                <label htmlFor="WorkingCapitalCCC" className="cursor-pointer">
                  <input
                    type="checkbox"
                    id="WorkingCapitalCCC"
                    checked={values.workingCapitalCCCDateWithoutInventory}
                    onChange={handleChange(
                      "workingCapitalCCCDateWithoutInventory"
                    )}
                  />
                  Working Capital CCC Date Without Inventory
                  </label>
                  <img
                    src="/assets/images/WorkingCapitalCCCDateWithoutInventory.jpg"
                    className="img-fluid"
                    alt="WorkingCapitalCCCDateWithoutInventory"
                    onClick={() => openImage("/assets/images/WorkingCapitalCCCDateWithoutInventory.jpg")}
                  />
              </div>
            </div>
            {values.inventory && <div className="col-md-6 rendering">
              <div className="form-group form-submission">
                <label
                  htmlFor="WorkingCapitalCCCAmountINV"
                  className="cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="WorkingCapitalCCCAmountINV"
                    checked={values.workingCapitalCCCAmountWithInventory}
                    onChange={handleChange(
                      "workingCapitalCCCAmountWithInventory"
                    )}
                  />
                  Working Capital CCC Amount With Inventory
                  </label>
                  <img
                    src="/assets/images/WorkingCapitalCCCAmountWithInventory.jpg"
                    className="img-fluid"
                    alt="WorkingCapitalCCCAmountWithInventory"
                    onClick={() => openImage("/assets/images/WorkingCapitalCCCAmountWithInventory.jpg")}
                  />
              </div>
            </div>}
            <div className="col-md-6 rendering">
              <div className="form-group form-submission">
                <label
                  htmlFor="WorkingCapitalCCCAmount"
                  className="cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="WorkingCapitalCCCAmount"
                    checked={values.workingCapitalCCCAmountWithoutInventory}
                    onChange={handleChange(
                      "workingCapitalCCCAmountWithoutInventory"
                    )}
                  />
                  Working Capital CCC Amount Without Inventory
                  </label>
                  <img
                    src="/assets/images/WorkingCapitalCCCAmountWithoutInventory.jpg"
                    className="img-fluid"
                    alt="WorkingCapitalCCCAmountWithoutInventory"
                    onClick={() => openImage("/assets/images/WorkingCapitalCCCAmountWithoutInventory.jpg")}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageView open={open} src={source} onClose={onClose} />
    </>
  );
};

export default RenderingTemplate;
