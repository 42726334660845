import React from 'react'
import { Container, Typography, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const StepThree = ({ prevStep, nextStep, handleChange, values }) => {

	const Continue = e => {
		e.preventDefault();
		nextStep();
	}

	const Previous = e => {
		e.preventDefault();
		prevStep();
	}

	return (		
		<>
			<div id="content" className="p-2">
				<div className="form-submission">
					<div className="col-lg-12">
						<div className="card-header mb-4">
							<h4 className="card-title">Generate Presentation - Statement type</h4>
							<h3>Phase 1 : Analysis supported by default hence select option disabled</h3>
						</div>
						<div className="row">
							<div className="col-lg-12">				
								<form className="">
									<div className="form-group">
										<input type="checkbox" id="AnalysysStatement" disabled checked />
										<label htmlFor="AnalysysStatement">Analysys Statement</label>
									</div>						
								</form>
								<input type="button" className="btn btn-success-custom previous mr-3" value="Previous" onClick={Previous} />
								<input type="button" className="btn btn-success-custom next ml-3" value="Next" onClick={Continue} />
							</div>
						</div>
					</div>
				</div>
			</div>	

			<NotificationContainer id="stepThreeError" />    	
		</>
	)
}

export default StepThree