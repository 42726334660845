import React, { useState, useRef } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, TextField, Button, ImageList, ImageListItem, ImageListItemBar } from '@material-ui/core'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const StepTwo = ({ prevStep, nextStep, handleChange, values }) => {
 
  // for continue event listener
  const Continue = e => {   
    e.preventDefault();
   
    //NotificationManager.remove();
    /*
    if (values.rbtitle.trim() === "") {
      NotificationManager.error("Please enter a title", "Error");
    } else if (values.rbdepartment.trim() === "") {
      NotificationManager.error("Please enter a department", "Error");
      return;
    } else if (values.rbmobile.trim() === "") {
      NotificationManager.error("Please enter a mobile number", "Error");
      return;
    } else {
        nextStep();
    }
    */
    
    nextStep();
    
  };
  //console.log("Updated state 2:", values); // Log the updated state

  const Previous = e => {
    e.preventDefault();
    prevStep();
  };

  return (    
    <>
      <div id="content" className="p-2">
        <div className="form-submission">
            <div className="col-lg-12">
              <div className="card-header mb-4">
                <h4 className="card-title">Generate Presentation</h4>
                <h3>Relationship Manager Details</h3>
              </div>        
              <div className="row">
                  <div className="col-lg-12">            
                      <div className="php-email-form">
                        <div className="row gy-4">
                          <div className="col-md-6">
                            <input type="text" className="form-control" placeholder="First Name" value={values.rbfirstname} readOnly={true} />
                          </div>
                          <div className="col-md-6 ">
                            <input type="text" className="form-control" placeholder="Last Name" value={values.rblastname} readOnly={true} />
                          </div>                
                          <div className="col-md-6">
                            <input type="text" className="form-control" placeholder="Email" value={values.rbemail} readOnly={true} />
                          </div>
                          <div className="col-md-6 ">
                            <input type="text" className="form-control" placeholder="Title" onChange={handleChange('rbtitle')} value={values.rbtitle} />
                          </div>                
                          <div className="col-md-6">
                            <input type="text" className="form-control" placeholder="Department" onChange={handleChange('rbdepartment')} value={values.rbdepartment} />
                          </div>
                          <div className="col-md-6 ">
                            <input type="text" className="form-control" placeholder="Mobile" onChange={handleChange('rbmobile')} value={values.rbmobile} />
                          </div>
                          <input type="button" className="btn btn-success-custom previous ml-3" value="Previous" onClick={Previous} />
                          <input type="button" className="btn btn-success-custom next ml-3" value="Next" onClick={Continue} />
                        </div>
                      </div>
                    <img src="/assets/images/Introslide.jpg" alt="" title="" className="mt-3 img-fluid" />
                  </div>
                </div>
            </div>
          </div>
      </div> 

      <NotificationContainer id="stepTwoError" />   
    </>
  )
}

export default StepTwo