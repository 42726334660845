import React, { Component, useState  } from "react";
//import { withRouter, useParams, browserHistory } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

//class Layout extends Component {
const Layout = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  //render() {
      return (
        <React.Fragment>
          <Header toggleSidebar={toggleSidebar} />
          <div className="wrapper d-flex align-items-stretch">
          {/* <div className={`wrapper d-flex align-items-stretch ${sidebarOpen ? '' : 'toggled'}`}> */}
            <Sidebar sidebarOpen={sidebarOpen} />
            <div id="content" className="p-2">
              {props.children}
            </div>
          </div>
          <Footer />
        </React.Fragment>
      );
  //}
};

export default Layout;