import React, { Component } from "react"
import RelationshipManager from "./RelationshipManager";
import ClientDetails from "./ClientDetails";
import WorkingCapitalInput from "./WorkingCapitalInput";
import RenderingTemplate from "./RenderingTemplate";
import { FinanCialAnalysis, RunHistoryService } from "../../../services";
import toastr, { error } from 'toastr';
import 'toastr/build/toastr.css';
import ReactDOM from 'react-dom';
import FileWebViewer from '../../FilesWebViewer/FileWebViewer';
import LiquidityRatio from "./LiquidityRatio";
import WCRatio from "./WCRatio";
import {Tab, Tabs} from 'react-bootstrap';
import PresentationType from "./PresentationType";

class WCStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            loading: false,
            isGenerated: false,
            genFileURL: '',
            presentationType:"",
            /* Relaship Manager Details */
            rbemail: '',
            rbtitle: '',
            rbdepartment: '',
            rbfirstname: '',
            rblastname: '',
            rbmobile: '',
            /* Client Details */
            cfirstname: '',
            clastname: '',
            cemail: '',
            cmobile: '',
            clogo: null,
            clogourl: null,
            fileType: null,
            cindustry: '',
            ccreditstate: 'no',
            canualrevenue: '',
            // Working Capital
            workingCapital: false,
            company: '',
            totalSaleRevenue: '',
            cogs: '',
            accountReceivables: '',
            accountPayables: '',
            loanIntRate: '',
            investmentIntRate: '',
            creditRate: '',
            inventory: '',
            loanBalance:'',
            currentAssets:'',
            currentLiabilities:'',
            totalAssets: '',


            companyError: '',
            totalSaleRevenueError: '',
            cogsError: '',
            accountReceivablesError: '',
            accountPayablesError: '',
            loanIntRateError: '',
            investmentIntRateError: '',
            creditRateError: '',
            inventoryError: '',
            loanBalanceError:'',
            currentAssetsError:'',
            currentLiabilitiesError:'',
            totalAssetsError: '',

            // Working Capital
            // Choose Graphical / Chart View
            workingCapitalWithInventory: false,
            workingCapitalWithoutInventory: false,
            workingCapitalCCCDateWithInventory: false,
            workingCapitalCCCDateWithoutInventory: false,
            workingCapitalCCCAmountWithInventory: false,
            workingCapitalCCCAmountWithoutInventory: false,
            // End of Graphical and Chart View
            pname: 'Treasury and Liquidity Management Presentation',
            logoPreview: "",
            clogoUrl: "",
            tabKey: "input-data",


            clientFileData: null,
            clientId: null,
            financialDataId: null,
            runHistoryId: null,

            wcCalculatedData: {
                accountReceivable: new Map(),
                accountPayable: new Map(),
                inventory: new Map(),
                total: new Map(),
            },
            liquidityRatioCalculated: {
                currentRatio: new Map(),
                assetsTurnoverRatio: new Map(),
                quickRatio: new Map()
            }

        };
    }

    componentDidMount() {
        switch(this.state.step) {
            case 1:
                case 2:
                    case 3:
                        this.setState({tabKey: 'input-data'});
                        break;
            case 4:
                this.setState({tabKey: 'wc-ratio'});
            break;
            case 5:
            case 6:
                this.setState({tabKey: 'liquidity-ratio'});
            break;
            default: this.setState({tabKey: 'input-data'});
        }
        this.setState({
            rbfirstname: this.props.user?.given_name || "",
            rblastname: this.props.user?.family_name || "",
            rbemail: this.props.user?.email || ""
        });
    }

    validateWcData = () => {
        if(this.state.step === 3) {
            // check
            const errorObject = {};
            if(!this.state.company) {
                errorObject['companyError'] = "Company name is required.";
            } else {
                errorObject['companyError'] = "";
            }
            if(!this.state.totalSaleRevenue) {
                errorObject['totalSaleRevenueError'] = "Sale is required.";
            } else {
                errorObject['totalSaleRevenueError'] = "";
            }
            if(!this.state.cogs) {
                errorObject['cogsError'] = "Cost of Good Sold is required.";
            } else {
                errorObject['cogsError'] = "";
            }
            if(!this.state.accountReceivables) {
                errorObject['accountReceivablesError'] = "Account Receivable is required.";
            } else {
                errorObject['accountReceivablesError'] = "";
            }
            if(!this.state.accountPayables) {
                errorObject['accountPayablesError'] = "Account Payable is required.";
            } else {
                errorObject['accountPayablesError'] = "";
            }

            if(!this.state.loanIntRate) {
                errorObject['loanIntRateError'] = "Loan Intereset Rate is required.";
            } else {
                errorObject['loanIntRateError'] = "";
            }
            if(!this.state.investmentIntRate) {
                errorObject['investmentIntRateError'] = "Investmenet Interest Rate is required.";
            } else {
                errorObject['investmentIntRateError'] = "";
            }
            if(!this.state.creditRate) {
                errorObject['creditRateError'] = "Earning's Credit Rate is required.";
            } else {
                errorObject['creditRateError'] = "";
            }
            if(!this.state.inventory) {
                errorObject['inventoryError'] = "Inventory is required.";
            } else {
                errorObject['inventoryError'] = "";
            }
            if(!this.state.loanBalance) {
                errorObject['loanBalanceError'] = "Outstanding Loan Balance is required.";
            } else {
                errorObject['loanBalanceError'] = "";
            }
            if(!this.state.currentAssets) {
                errorObject['currentAssetsError'] = "Current Assets is required.";
            }else {
                errorObject['currentAssetsError'] = "";
            }
            if(!this.state.currentLiabilities) {
                errorObject['currentLiabilitiesError'] = "Current Liabilities is required.";
            } else {
                errorObject['currentLiabilitiesError'] = "";
            }
            if(!this.state.totalAssets) {
                errorObject['totalAssetsError'] = "Total Assets is required.";
            } else {
                errorObject['totalAssetsError'] = "";
            }
            if(Object.keys(errorObject).length) {
                this.setState({...errorObject});
                return !Object.entries(errorObject).some((value) => {
                    return !(value[1])
                });
            }
        }
        return false;
    }

    Continue() {
        // if(this.validateWcData()) {
        //     return;
        // }
        switch(this.state.step + 1) {
            case 1:
                case 2:
                    case 3:
                        this.setState({tabKey: 'input-data'});
                        break;
            case 4:
                this.setState({tabKey: 'wc-ratio'});
            break;
            case 5:
            case 6:
                this.setState({tabKey: 'liquidity-ratio'});
            break;
            default: this.setState({tabKey: 'input-data'});
        }


        this.setState({
            step: this.state.step + 1
        });
    }

    Previous(){
        switch(this.state.step - 1) {
            case 1:
                case 2:
                    case 3:
                        this.setState({tabKey: 'input-data'});
                        break;
            case 4:
                this.setState({tabKey: 'wc-ratio'});
            break;
            case 5:
            case 6:
                this.setState({tabKey: 'liquidity-ratio'});
            break;
            default: this.setState({tabKey: 'input-data'});
        }
        this.setState({
            step: this.state.step - 1
        });
    }
    handleChange = (name) => e => {
        if(name === 'presentationType') {
            console.log(e.target.value);
        }
        if(name === 'clogo') {
            const files = e.target.files;
            if(!files.length) {
                toastr.error("Please select a logo");
                return;
            }
            const type = files[0].type;
            const regexp = new RegExp('image/*', 'i');
            if(!regexp.test(type)) {
                toastr.error("Only jpg and png are allowed.");
                return;
            }
            // Size for client logo
            if(files[0].size > 563200) {
                toastr.error("Size must be below 550KB");
                return;
            }
            const blob = new Blob([files[0]]);
            const url = URL.createObjectURL(blob, type);
            this.setState({
                clogo: files[0],
                logoPreview: url,
                clogoUrl: url,
                clogourl: url, // text box preview of url
                fileType: type,
                clientFileData: null,
                clientId:null,
                financialDataId: null,
                runHistoryId: null
            });
            this.clientLogoUploaded = false;
            this.clientDataSaved = false;
            this.financialAnalysisDataSaved = false;
            return;
        }
        if(name === 'clogourl') {
            console.log(e);
            this.setState({
                clogo: e.target.value,
                logoPreview: e.target.value,
                clogoUrl: e.target.value,
                clientFileData: null,
                clientId: null,
                financialDataId: null,
                runHistoryId: null
            });
            // set the file type to the state from the url
            this.getFIleType(e.target.value);
            this.clientLogoUploaded = false;
            this.clientDataSaved = false;
            this.financialAnalysisDataSaved = false;
            return;
        }
        const clientKeys = [
            'cfirstname',
            'clastname',
            'cemail',
            'cmobile',
            'clogo',
            'clogourl',
            'cindustry',
            'ccreditstate',
            'canualrevenue'
        ];

        if(clientKeys.includes(name)) {
            this.setState({clientId:null, financialDataId: null, runHistoryId: null});
            this.clientDataSaved = false;
            this.financialAnalysisDataSaved = false;
        }
        let value = e.target.value;
        const renderSlidesKeys = [
            "workingCapitalCCCAmountWithInventory",
            "workingCapitalCCCAmountWithoutInventory",
            "workingCapitalCCCDateWithInventory",
            "workingCapitalCCCDateWithoutInventory",
            "workingCapitalWithInventory",
            "workingCapitalWithoutInventory"
        ];
        if(renderSlidesKeys.includes(name)) {
            value = e.target.checked;
        }
        const numberFields = [
            "totalSaleRevenue",
            "cogs",
            "accountReceivables",
            "accountPayables",
            "loanIntRate",
            "inventory",
            "investmentIntRate",
            "creditRate",
            "loanBalance",
            "currentAssets",
            "currentLiabilities",
            "totalAssets"
        ];
        if(numberFields.includes(name) && (value !== '' && !Number(value))) {
            return false;
        }

        if([...numberFields, 'company'].includes(name)) {
            this.setState({
                financialDataId: null
            });
            this.financialAnalysisDataSaved = false;
        }
        

        this.setState({
            [name]: value
        });
    }


    getFIleType = async (url) => {
        const res = await fetch(url, {method:'HEAD', mode: 'no-cors' });
        this.setState({
            fileType: res.headers.get('Content-Type')
        });
    }

    onWCDataCalculated = (data, key) => {
        this.setState({
            [key]: data
        });
    }

    clientDataSaved = false;
    clientLogoUploaded = false;
    financialAnalysisDataSaved = false;
    runHistorySaved = false;

    async onSubmit() {
        /**
         * 1. Submit to a JAVA endpoint for now
         */
        this.setState({
            loading: true,
            isGenerated: false,
            genFileURL: ''
        });
        // save the client
        const data = this.state;
        // save the logo
        let logoResponse = this.state.clientFileData;
        if(!this.clientLogoUploaded) {
            if(data.clogo instanceof File) {
                logoResponse =  (await FinanCialAnalysis.addClientLogo(data.clogo));
                if(logoResponse.error) {
                    toastr.error("Client logo upload failed.");
                    this.setState({loading: false});
                    return;
                }
                this.setState({clientFileData: logoResponse.data});
                logoResponse = logoResponse.data;
            } else {
                // To maintain the same flow if the user chooses the url instead of file logo
                this.setState({clientFileData: {fileLink: data.clogo}});
                logoResponse = {fileLink: data.clogo};
            }
            this.clientLogoUploaded = true;
        }
        let clientResponse = {};
        if(!this.clientDataSaved) {
            clientResponse = await FinanCialAnalysis.addPPTClient({
                first_name: data.cfirstname,
                last_name: data.clastname,
                email: data.cemail,
                mobile: data.cmobile,
                client_logo: (this.state.clientFileData && this.state.clientFileData.fileLink) || logoResponse.fileLink,
                industry: data.cindustry,
                credit_client: data.ccreditstate === 'yes',
                annual_revenue: parseFloat(data.canualrevenue)
            });
            if(clientResponse.error) {
                // client data could not be saved
                toastr.error(clientResponse.message)
                this.setState({loading: false});
                return;
            }
            this.setState({clientId: clientResponse.data.data._id});
            this.clientDataSaved = true;
        }
        let wcFARes = null;
        if(!this.financialAnalysisDataSaved) {
            // save the wc and liquidity ratio data
            if(!this.state.wcCalculatedData.accountPayable.size 
                || !this.state.wcCalculatedData.accountReceivable.size 
                || !this.state.wcCalculatedData.inventory.size 
                || !this.state.wcCalculatedData.total.size) {
                    toastr.error("Working Capital Data is not ready.");
                    return;
            }
            
            if(!this.state.liquidityRatioCalculated.currentRatio.size || !this.state.liquidityRatioCalculated.assetsTurnoverRatio.size || !this.state.liquidityRatioCalculated.quickRatio.size) {
                toastr.error("Liquidity Ratio data is not ready..");
                return;
            }
            
            // now call the api to save
            wcFARes = await FinanCialAnalysis.saveFinancialAnalysisData({
                company: this.state.company,
                loanIntRate: parseFloat(this.state.loanIntRate),
                investmentIntRate: parseFloat(this.state.investmentIntRate),
                creditRate: parseFloat(this.state.creditRate),
                loanBalance: parseFloat(this.state.loanBalance),
                clientId: this.state.clientId || clientResponse.data.data._id,
                ...this.state.wcCalculatedData,
                ...this.state.liquidityRatioCalculated
            });
            if(wcFARes.error) {
                toastr.error(wcFARes.message, "Financial Analysis error");
                return;
            }
            this.financialAnalysisDataSaved = true;
            this.setState({financialDataId: wcFARes.data._id});
        }

        
        const res = await FinanCialAnalysis.generateWCDataRatio({...this.state});
        if(!res.error) {
            // save the client and run history
            
            const response = await RunHistoryService.addRunHistory({
                _id: this.state.runHistoryId,
                client_name: res.data.fileName,
                ppt_file: res.data.fileUrl,
                type: "working-capital",
                financial_analysis: this.state.financialDataId || (wcFARes && wcFARes.data._id),
                client: this.state.clientId || clientResponse.data.data._id
            });
            if(response.error) {
                // show toast
                toastr.error(response.message)
                this.setState({loading: false});
                return;
            }
            this.setState({runHistoryId:response.data.data._id });
            this.runHistorySaved = true;
            toastr.success("PPT File Generated Successfully.");
        } else {
            toastr.error(res.message);
            this.setState({loading: false});
            return;
        }
        this.setState({
            isGenerated: true,
            genFileURL: res.data.fileUrl
        })
        this.setState({loading: false});
    }
    handleViewClick() {
        const newWindow = window.open('', '_blank');
        newWindow.document.write('<html><head><title>File Viewer</title></head><body><div id="fileViewerRoot"></div></body></html>');
        newWindow.document.close();
        const fileViewerRoot = newWindow.document.getElementById('fileViewerRoot');
        ReactDOM.render(<FileWebViewer url={this.state.genFileURL} />, fileViewerRoot);
      }; 
    render() {
        return (
            <>
                <div id="content" className="p-2">
                    <div className="form-submission">
                            <div className="card-header mb-4">
                                    <h3 className="card-title">Client Financial Performance Ratio</h3>
                            </div>
                            <Tabs 
                            id="controlled-tab-example"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => {alert("Not Allowed. You have to follow the steps.")}}
                            className="mb-3 nav-pills nav-fill"
                            >
                                <Tab eventKey="input-data" title="Input Data">
                                    {
                                        this.state.step === 0 && <PresentationType values={this.state} handleChange={this.handleChange}/>
                                    }
                                    {
                                        this.state.step === 1 && <RelationshipManager values={this.state} handleChange={this.handleChange} />
                                    }
                                    {
                                        this.state.step === 2 && <ClientDetails values={this.state} handleChange={this.handleChange} />
                                    }
                                    {
                                        this.state.step === 3 && <WorkingCapitalInput values={this.state} handleChange={this.handleChange}/>
                                    }
                                </Tab>
                                <Tab eventKey="wc-ratio" title="WC Ratio">
                                    {
                                        this.state.step === 4 && <WCRatio values={this.state} handleChange={this.handleChange} onWCDataCalculated={this.onWCDataCalculated} />
                                    }
                                </Tab>
                                <Tab eventKey="liquidity-ratio" title="Liquidity Ratio">
                                    {
                                        this.state.step === 5 && <LiquidityRatio values={this.state} handleChange={this.handleChange} onWCDataCalculated={this.onWCDataCalculated} />
                                    }
                                    {
                                        this.state.step === 6 && this.state.presentationType === 'external' && <RenderingTemplate values={this.state} handleChange={this.handleChange} />
                                    }
                                </Tab>
                                <Tab title="Financial Spreads"></Tab>
                                <Tab title="Leverage Ratio"></Tab>
                            </Tabs>
                            {/* <ul className="nav nav-pills nav-fill mb-1" id="financial-analysis-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="input-tab" data-bs-toggle="tab" data-bs-target="#input-data" type="button" role="tab" aria-controls="input-data" 
                                aria-selected="true">Input Data</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="wc-ratio-tab" data-bs-toggle="tab" data-bs-target="#wc-ratio" type="button" role="tab" aria-controls="wc-ratio" 
                                aria-selected="true">WC Ratio</button>
                                </li>
                                <li className="nav-item" role="presentation" >
                                    <button className="nav-link" id="liquidity-ratio-tab" data-bs-toggle="tab" data-bs-target="#liquidity-ratio" type="button" role="tab" aria-controls="liquidity-ratio" 
                                aria-selected="true">Liquidity Ratio</button>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link disabled" href="#" tabindex="-1">Financial Spreads</button>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link disabled" href="#" tabindex="-1">Leverage Ratio</button>
                                </li>
                            </ul> */}
                            {/* <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="input-data" role="tabpanel" aria-labelledby="input-tab">
                                    {
                                        this.state.step === 1 && <RelationshipManager values={this.state} handleChange={this.handleChange} />
                                    }
                                    {
                                        this.state.step === 2 && <ClientDetails values={this.state} handleChange={this.handleChange} />
                                    }
                                    {
                                        this.state.step === 3 && <WorkingCapitalInput values={this.state} handleChange={this.handleChange} />
                                    }
                                </div>
                                <div className="tab-pane fade" id="wc-ratio" role="tabpanel" aria-labelledby="wc-ratio-tab">
                                    {
                                        this.state.step === 4 && <WCRatio values={this.state} handleChange={this.handleChange} />
                                    }
                                </div>
                                <div className="tab-pane fade" id="liquidity-ratio" role="tabpanel" aria-labelledby="liquidity-ratio-tab">
                                    {
                                        this.state.step === 5 && <LiquidityRatio values={this.state} handleChange={this.handleChange} />
                                    }
                                    {
                                        this.state.step === 6 && <RenderingTemplate values={this.state} handleChange={this.handleChange} />
                                    }
                                </div>
                            </div> */}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <p className="p-button-holder">
                                {this.state.step > 1 &&  <input type="button" className="btn btn-success-custom previous mr-3" value="Previous" onClick={ this.Previous.bind(this) } />}
                                {this.state.presentationType === 'external' && <button type="button" className="btn btn-success-custom next ml-3" onClick={ this.state.step < 6 ? this.Continue.bind(this) : this.onSubmit.bind(this) }> 
                                {
                                    this.state.loading && <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only">Generating...</span>
                                        Generating...
                                    </>
                                }
                                {!this.state.loading && (this.state.step < 6 ? "Next" : "Generate PPT")}
                                </button>
                                }
                                {
                                    this.state.presentationType === 'virtual' && this.state.step !== 5 && 
                                    <button type="button" className="btn btn-success-custom next ml-3" onClick={  this.Continue.bind(this) }>Next</button>
                                }
                            </p>
                        </div>
                    </div>
                        {
                            this.state.isGenerated && this.state.genFileURL &&
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                        {
                                            this.state.isGenerated && this.state.genFileURL ? (
                                            <>
                                            <a className="btn btn-success btn-success-href" href={this.state.genFileURL}>
                                                Download
                                            </a>
                                            <button className="btn btn-success btn-success-href ml-3" onClick={this.handleViewClick.bind(this)}>
                                                View
                                            </button>
                                            </>
                                            ) : null
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        }
                    {/* </div> */}
                </div>
    
            
            </>
        )
    }
}

export default WCStep;