import React from 'react';

const RelationshipManager = ({  handleChange, values }) => {
  return (    
    <>
      <div className="row">
        <div className='col-12'>
        <h4>Relationship Manager Details</h4>
        </div>
          <div className="col-lg-12">            
              <div className="php-email-form">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <label className='font-weight-bold'>First Name:</label>
                    <input type="text" className="form-control" placeholder="First Name" value={values.rbfirstname} readOnly={true} />
                  </div>
                  <div className="col-md-6 ">
                  <label className='font-weight-bold'>Last Name:</label>
                    <input type="text" className="form-control" placeholder="Last Name" value={values.rblastname} readOnly={true} />
                  </div>                
                  <div className="col-md-6">
                  <label className='font-weight-bold'>Email:</label>
                    <input type="text" className="form-control" placeholder="Email" value={values.rbemail} readOnly={true} />
                  </div>
                  <div className="col-md-6 ">
                    <label className='font-weight-bold'>Title:</label>
                    <input type="text" className="form-control" placeholder="Title" onChange={handleChange('rbtitle')} value={values.rbtitle} />
                  </div>                
                  <div className="col-md-6">
                    <label className='font-weight-bold'>Department:</label>
                    <input type="text" className="form-control" placeholder="Department" onChange={handleChange('rbdepartment')} value={values.rbdepartment} />
                  </div>
                  <div className="col-md-6 ">
                    <label className='font-weight-bold'>Mobile:</label>
                    <input type="text" className="form-control" placeholder="Mobile" onChange={handleChange('rbmobile')} value={values.rbmobile} />
                  </div>
                </div>
              </div>
          </div>
        </div> 
    </>
  )
}

export default RelationshipManager;