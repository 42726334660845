import axios, { AxiosError } from "axios";
import { RUN_HISTORY_URL } from "../config";
export const RunHistoryService = {
    async addRunHistory(runHistoryData) {
        try {
            const response = await axios.post(`${RUN_HISTORY_URL}`, runHistoryData);
            return {error: false, message: response.data.message, data: response.data};
        } catch(e) {
            if(e instanceof AxiosError) {
                return {error: true, message: e.response.data.message, data: e.response.data};
            }
            return {error: true, message: "Internal Server Error"};
        }
    }
}