import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, Radio, RadioGroup } from '@material-ui/core'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';



const StepOne = ({ nextStep, handleChange, values }) => {  
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    if(values.isinternalview) {
      setSelectedOption('internal');
    } else if(values.isexternalview) {
      setSelectedOption('external');
    }
  }, []);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const Continue = (e) => {
    e.preventDefault();
    
    //if(selectedOption === "") {
    /*
    if(values.isexternalview === false && values.isinternalview === false){
      NotificationManager.error("Please select an option", "Error");
    } else {
      nextStep();
    }
    */
    
    nextStep();
    
  };
  //console.log("Updated state 1:", values); // Log the updated state

  return (   
    <>
      <div id="content" className="p-2">
        <div className="form-submission">
          <div className="col-lg-12">
              <div className="card-header mb-4">
                <h4 className="card-title">Presentation Name</h4>
                <h3>Treasury and Liquidity Management Presentation</h3>
              </div>
            <h5 className="ml-3">Presentation Type:</h5>
              <div className="row">
                  <div className="col-lg-12">              
                    <div className="ml-3">
                      <div className="form-group">
                        <input type="radio" name="presentationType" id="internal" value="internal" checked={selectedOption === "internal"} onChange={() => {handleChange("isinternalview")({ target: { checked: true } }); handleOptionChange('internal');}} />
                        <label htmlFor="internal">Internal Presentation</label>
                      </div>
                      <div className="form-group">
                        <input type="radio" name="presentationType" id="external" value="external" checked={selectedOption === "external"} onChange={() => {handleChange("isexternalview")({ target: { checked: true } }); handleOptionChange('external');}} />
                        <label htmlFor="external">External Presentation</label>
                      </div>
                    </div>
                    <input type="button" className="btn btn-success-custom next ml-3" value="Next" onClick={Continue} />
                  </div>
              </div>
            </div>
        </div>
      </div>

      <NotificationContainer id="stepOneError" />
    </>  
  )
}

export default StepOne