import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useCookies } from 'react-cookie';
import AppNavbar from '../AppNavbar';
import { BASE_API_URL } from "../../config";

const BankerAddEdit = () => {
    const initialFormState = {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        bankingVetical: '',
        branchName: '',
        department: '',
        primaryContactNumber: '',
        primaryContactType: '',
        secondaryContactNumber: '',
        secondaryContactType: '',
        bankName: 'Regions'
    };
    const [group, setGroup] = useState(initialFormState);
    const navigate = useNavigate();
    const { param } = useParams();
    const [cookies] = useCookies(['XSRF-TOKEN']);

    useEffect(() => {
        if(param !== 'add') {
            fetch(`${BASE_API_URL}/api/bankers/${param}`, { credentials: 'include' })
            .then(response => response.json())
            .then(data => setGroup(data));
        }
    }, [param, setGroup]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setGroup({ ...group, [name]: value });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response = await fetch(`${BASE_API_URL}/api/bankers${group.id ? `/${group.id}` : ''}`, {
            method: group.id ? 'PUT' : 'POST',
            headers: {
                'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(group),
            credentials: 'include'
        });

        console.log("Response state: " + response.status);
        if(response.status === 403) {
            alert("Not Authorized to update or add a banker!!!!");
        } else {
            setGroup(initialFormState);
            navigate('/banker/list');
        }
    }

    const title = <>{group.id ? 'Edit Banker' : 'Add Banker'}</>;

    return (
        <>
            <div id="content" className="p-2">
                <div className="form-submission">
                    <div className="col-lg-12">
                        <div className="card-header mb-4">
                            {/* <h4 className="card-title">...</h4> */}
                            <h3>{ title }</h3>
                        </div>
                        <div className="row">
                            <div>
                                <form action="#" method="post" className="php-email-form" onSubmit={handleSubmit}>
                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" placeholder="First Name" name="firstName" id="firstName" required={true} value={group.firstName || ''} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Last Name" name="lastName" id="lastName" required={true} value={group.lastName || ''} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Email" name="email" id="email" required={true} value={group.email || ''} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" placeholder="Title" name="title" id="title" required={true} value={group.title || ''} onChange={handleChange} />
                                        </div>                                        
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" placeholder="Banking Vertical" name="bankingVetical" id="bankingVetical" value={group.bankingVetical || ''} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" placeholder="Branch Name" name="branchName" id="branchName" value={group.branchName || ''} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Department" name="department" id="department" value={group.department || ''} onChange={handleChange}  />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Primary Contact Number" name="primaryContactNumber" id="primaryContactNumber" value={group.primaryContactNumber || ''} onChange={handleChange}  />
                                        </div>

                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Primary Contact Type" name="primaryContactType" id="primaryContactType" value={group.primaryContactType || ''} onChange={handleChange}  />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Secondary Contact Number" name="secondaryContactNumber" id="secondaryContactNumber" value={group.secondaryContactNumber || ''} onChange={handleChange}  />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Secondary Contact Type" name="secondaryContactType" id="secondaryContactType" value={group.secondaryContactType || ''} onChange={handleChange}  />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="text" className="form-control" placeholder="Bank Name" name="bankName" id="bankName" value={group.bankName || 'Regions'} onChange={handleChange}  />
                                        </div>
                                        <p className="p-button-holder">
                                            <input type="submit" className="btn btn-success-custom mr-3 mt-1 text-center" value="Save" />
                                            <a href="/banker/list" className="btn btn-success-custom mt-1 text-center">Cancel</a> 
                                        </p>                                       
                                    </div>          
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     

            <NotificationContainer />  
        </>
    )
};

export default BankerAddEdit;
