import React from 'react'
import { Container, Typography, Grid, TextField, Button, ImageList, ImageListItem, ImageListItemBar, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'

const StepSeven = ({prevStep, nextStep, handleChange, values}) => {

    const [checked, setChecked] = React.useState(true);

    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }

    return (
        <>
            <div id="content" className="p-2">
            <div className="form-submission">
                <div className="col-lg-12">
                    <div className="card-header mb-4">
                        <h4 className="card-title">Generate Presentation</h4>
                        <h3>Choose Rendering Options</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="row gy-4">
                            <div className="col-md-6 rendering">
                                <div className="form-group form-submission">
                                    <label htmlFor="PieChart" className='cursor-pointer'>
                                        Pie Chart View
                                        <input type="checkbox" id="PieChart" checked={values.ispieview} onChange={handleChange('ispieview')} />
                                        <img src="/assets/images/PieChartSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 rendering">
                                <div className="form-group form-submission">
                                    <label htmlFor="SmartChart" className='cursor-pointer'>
                                        <input type="checkbox" id="SmartChart" checked={values.issmartview} onChange={handleChange('issmartview')} />
                                        Smart Chart View
                                        <img src="/assets/images/SmartChartSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 rendering ">
                                <div className="form-group form-submission">
                                    <label htmlFor="SummaryChart" className='cursor-pointer'>
                                        <input type="checkbox" id="SummaryChart" checked={values.isstatsview} onChange={handleChange('isstatsview')} />
                                        Summary Chart View
                                        <img src="/assets/images/SummaryViewSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 rendering ">
                                <div className="form-group form-submission">
                                    <label htmlFor="Recommendation" className='cursor-pointer'>
                                        <input type="checkbox" id="Recommendation" checked={values.isrecomendedview} onChange={handleChange('isrecomendedview')} />
                                        Recommendation View
                                        <img src="/assets/images/RecommendationViewSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 rendering">
                                <div className="form-group form-submission">
                                    <label htmlFor="SmartArt" className='cursor-pointer'>
                                        <input type="checkbox" id="SmartArt" checked={values.issmartartview} onChange={handleChange('issmartartview')} />
                                        Smart Art View
                                        <img src="/assets/images/SmartProtectViewSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 rendering">
                                <div className="form-group form-submission">
                                    <label htmlFor="SmartService" className='cursor-pointer'>
                                        <input type="checkbox" id="SmartService" checked={values.issmartsrcvview} onChange={handleChange('issmartsrcvview')} />
                                        Smart Service View
                                        <img src="/assets/images/SmartServiceViewSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            {values.workingCapital && 
                                <>
                                    <div className="col-md-6 rendering">
                                        <div className="form-group form-submission">
                                            <label htmlFor="WorkingCapitalMapINV">Working Capital Map With Inventory
                                                <input type="checkbox" id="WorkingCapitalMapINV" checked={values.workingCapitalWithInventory} onChange={handleChange('workingCapitalWithInventory')}/>
                                                <img htmlFor="WorkingCapitalMapINV" src="/assets/images/WorkingCapitalMapWithInventory.jpg" className="img-fluid" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 rendering">
                                        <div className="form-group form-submission">
                                            <label htmlFor="WorkingCapitalMap" className='cursor-pointer'>
                                                <input type="checkbox" id="WorkingCapitalMap" checked={values.workingCapitalWithoutInventory} onChange={handleChange('workingCapitalWithoutInventory')}/>
                                                Working Capital Map Without Inventory
                                                <img src="/assets/images/WorkingCapitalMapWithoutInventory.JPG" className="img-fluid" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 rendering">
                                        <div className="form-group form-submission">
                                            <label htmlFor="WorkingCapitalCCCINV" className='cursor-pointer'>
                                                <input type="checkbox" id="WorkingCapitalCCCINV" checked={values.workingCapitalCCCDateWithInventory} onChange={handleChange('workingCapitalCCCDateWithInventory')} />
                                                Working Capital CCC Date With Inventory
                                                <img src="/assets/images/WorkingCapitalCCCDateWithInventory.jpg" className="img-fluid" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 rendering">
                                        <div className="form-group form-submission">
                                            <label htmlFor="WorkingCapitalCCC" className='cursor-pointer'>
                                                <input type="checkbox" id="WorkingCapitalCCC" checked={values.workingCapitalCCCDateWithoutInventory} onChange={handleChange('workingCapitalCCCDateWithoutInventory')} />
                                                Working Capital CCC Date Without Inventory
                                                <img src="/assets/images/WorkingCapitalCCCDateWithoutInventory.jpg" className="img-fluid" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 rendering">
                                        <div className="form-group form-submission">
                                            <label htmlFor="WorkingCapitalCCCAmountINV" className='cursor-pointer'>
                                                <input type="checkbox" id="WorkingCapitalCCCAmountINV" checked={values.workingCapitalCCCAmountWithInventory} onChange={handleChange('workingCapitalCCCAmountWithInventory')} />
                                                Working Capital CCC Amount With Inventory
                                                <img src="/assets/images/WorkingCapitalCCCAmountWithInventory.jpg" className="img-fluid" />
                                            </label>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 rendering">
                                        <div className="form-group form-submission">
                                            <label htmlFor="WorkingCapitalCCCAmount" className='cursor-pointer'>
                                                <input type="checkbox" id="WorkingCapitalCCCAmount" checked={values.workingCapitalCCCAmountWithoutInventory} onChange={handleChange('workingCapitalCCCAmountWithoutInventory')} />
                                                Working Capital CCC Amount Without Inventory
                                                <img src="/assets/images/WorkingCapitalCCCAmountWithoutInventory.jpg" className="img-fluid" />
                                            </label>
                                        </div>
                                    </div> 
                                </>
                            }
                            <div className="col-md-6 rendering">
                                <div className="form-group form-submission">
                                    <label htmlFor="Account" className='cursor-pointer'>
                                        <input type="checkbox" id="Account" checked={values.isaccountview} onChange={handleChange('isaccountview')} />
                                        Account View
                                        <img src="/assets/images/AccountViewSample.jpg" className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                            <p className="p-button-holder">
                                <input type="button" className="btn btn-success-custom previous mr-3 mt-3 text-center" value="Previous" onClick={ Previous } />
                                <input type="button" className="btn btn-success-custom next mt-3 text-center" value="Next" onClick={ Continue } />            
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>            
        </>
    )
}

export default StepSeven