import React, { useEffect, useState } from 'react';
import NumberSpan from '../../NumberSpan';
import './slide.css';
import { CommonService } from '../../../services';
const WCRatio = ({values, onWCDataCalculated}) => {
    const [workingCapitalResult, setWorkingCapitalResult] = useState({
        accountReceivable: new Map(),
        accountPayable: new Map(),
        inventory: new Map(),
        total: new Map(),
    });
    const [accountReceivableData, setAccountReceivableData] = useState(new Map());
    const [accountPayableData, setAccountPayableData] = useState(new Map());
    const [inventoryData, setInventoryData] = useState(new Map());

    const [rates, setRates] = useState([]);
    
    const [clientName, setClientName] = useState("Client Name");
    const [clientLogo, setClientLogo] = useState("");

    const WORKING_CAPITAL_DAYS = 365;
    const analysisDays = [1, 2, 3, 5, 10];
    useEffect(() => {
        // values = {
        //     cfname: "John",
        //     clname:"Doe",
        //     totalSaleRevenue: 5000000,
        //     cogs: 765000,
        //     accountReceivables: 1250000,
        //     accountPayables: 900000,
        //     loanIntRate: 6.5,
        //     investmentIntRate: 5,
        //     creditRate: 50,
        //     inventory: 565000,
        //     currentAssets: 0,
        //     currentLiabilities: 0,
        // };
        const {
            totalSaleRevenue,
            cogs,
            accountReceivables,
            accountPayables,
            loanIntRate,
            investmentIntRate,
            creditRate,
            inventory,
            // currentAssets,
            // currentLiabilities,
          } = values;
          setClientName(`${values.cfirstname} ${values.clastname}`);
          if(values.clogoUrl) {
            setClientLogo(values.clogoUrl);
          }
          // Now, you have extracted values in JavaScript constants:
        const saleRevenueFigure = parseFloat(totalSaleRevenue);
        const cogsValue = parseFloat(cogs);
        const accountReceivablesValue = parseFloat(accountReceivables);
        const accountPayablesValue = parseFloat(accountPayables);
        const loanIntRateValue = parseFloat(loanIntRate);
        const investmentIntRateValue = parseFloat(investmentIntRate);
        const creditRateValue = parseFloat(creditRate);
        const inventoryValue = inventory ? parseFloat(inventory) : 0;
        const accountReceivableMap = calculateAccountReceivable(accountReceivablesValue, saleRevenueFigure, loanIntRateValue, investmentIntRateValue, creditRateValue);
        const accountPayableMap = calculateAccountPayable(accountPayablesValue, cogsValue, loanIntRateValue, investmentIntRateValue, creditRateValue);
        const inventoryMap = inventory ? calculateInventory(inventoryValue, cogsValue, loanIntRateValue, investmentIntRateValue, creditRateValue) : new Map();
        setAccountReceivableData(accountReceivableMap);
        setAccountPayableData(accountPayableMap);
        setInventoryData(inventoryMap);
        setRates([
            {
                scenario: "Earning's Credit Rate",
                rate:creditRateValue
            },
            {
                scenario: "Investment Rate",
                rate: investmentIntRateValue
            },
            {
                scenario: "Loan Interest Rate",
                rate: loanIntRateValue
            }
        ]);
        // Total calculations
        const totalMap = new Map();
        totalMap.set("total_wc", (accountReceivablesValue + inventoryValue) - accountPayablesValue);
        totalMap.set("total_wc_avg", (accountReceivablesValue + inventoryValue + accountPayablesValue) / 3);
        totalMap.set("total_wc_avg_ar_ap", (accountReceivablesValue + accountPayablesValue) / 2);
        totalMap.set("total_avg_per_day", (accountReceivableMap.get("perDayAmountFigure") + accountPayableMap.get("perDayAmountFigure") + inventoryMap.get("perDayAmountFigure")) / 3);
        totalMap.set("total_avg_per_day_ar_ap", (accountReceivableMap.get("perDayAmountFigure") + accountPayableMap.get("perDayAmountFigure")) / 2);
        const addDaysValue = (accountReceivableMap.get("days") + inventoryMap.get("days"));
        const wcDaysValue = accountPayableMap.get("days") > addDaysValue ? (accountPayableMap.get("days") - addDaysValue) : addDaysValue - accountPayableMap.get("days");
        totalMap.set("wc_days", wcDaysValue);
        totalMap.set("wc_days_less_ap",  accountReceivableMap.get("days") > accountPayableMap.get("days") ? (accountReceivableMap.get("days") - accountPayableMap.get("days")) : (accountPayableMap.get("days") - accountReceivableMap.get("days")));

        // Store all results in a workingCapitalResult object
        setWorkingCapitalResult({
            accountReceivable: accountReceivableMap,
            accountPayable: accountPayableMap,
            inventory: inventoryMap,
            total: totalMap,
        });
        onWCDataCalculated({
            accountReceivable: accountReceivableMap,
            accountPayable: accountPayableMap,
            inventory: inventoryMap,
            total: totalMap,
        },'wcCalculatedData');

    }, []);

    const calculateAccountReceivable = (accountReceivables, saleRevenueFigure, loanIntRate, investmentIntRate, creditRate) => {
        const accountReceivableResult = new Map();
        // Calculate the first ARDOH
        let ardoh = (accountReceivables / saleRevenueFigure) * WORKING_CAPITAL_DAYS;
        ardoh = Math.round(ardoh);
        let perDayAmount = parseFloat((accountReceivables / ardoh).toFixed(2));
      
        accountReceivableResult.set("accountReceivableFigure", accountReceivables);
        accountReceivableResult.set("saleRevenueFigure", saleRevenueFigure);
        accountReceivableResult.set("ardohFigure", ardoh);
      
        // For the ease of coding
        accountReceivableResult.set("days", ardoh);
        accountReceivableResult.set("perDayAmountFigure", perDayAmount);
      
        return accountReceivableResult;
    }

    const calculateAccountPayable = (accountPayables, cogs, loanIntRate, investmentIntRate, creditRate) => {
            const accountPayableResult = new Map();
            // Calculate the first APDOH
            let apdoh = (accountPayables / cogs) * WORKING_CAPITAL_DAYS;
            apdoh = Math.round(apdoh);
            let perDayAmount = parseFloat((accountPayables / apdoh).toFixed(2));
        
            accountPayableResult.set("accountPayableFigure", accountPayables);
            accountPayableResult.set("cogs", cogs);
            accountPayableResult.set("apdohFigure", apdoh);
        
            accountPayableResult.set("days", apdoh);
            accountPayableResult.set("perDayAmountFigure", perDayAmount);
        
        return accountPayableResult;
    };

    const calculateInventory = (inventory, cogs, loanIntRate, investmentIntRate, creditRate) => {
        const inventoryResult = new Map();
        // Calculate the first INVDOH
        let invdoh = 0.00;
        let perDayAmount = 0.00;
        if (inventory > 0) {
          invdoh = (inventory / cogs) * WORKING_CAPITAL_DAYS;
          invdoh = Math.round(invdoh);
          perDayAmount = parseFloat((inventory / invdoh).toFixed(2));
        }
        inventoryResult.set("inventoryFigure", inventory);
        inventoryResult.set("cogs", cogs);
        inventoryResult.set("invdohFigure", invdoh);
        inventoryResult.set("days", invdoh);
        inventoryResult.set("perDayAmountFigure", perDayAmount);
      
        return inventoryResult;
    };

    return <>
        <div className='row'>
            <div className='col-12 text-center'>
                <h4 className=''>Working Capital Ratio - Cash Conversion Cycle</h4>
            </div>
        </div>
        <div className='row justify-content-between'>
            <div className='col-4'>
                <div className='bg-custom-lgreen p-1 text-light text-center'>
                    <h5>Account Receivable</h5>
                </div>
                <div style={{border: '1px solid'}} className='p-1 wc-card'>
                    <p className='d-inline-block'><b>AR:</b> <NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>Sales:</b> <NumberSpan number={accountReceivableData.get("saleRevenueFigure")} money={true} /></p> x <span className='text-primary'>{WORKING_CAPITAL_DAYS}</span> = <NumberSpan number={accountReceivableData.get("ardohFigure")} money={false} /> <b>ARDOH</b>
                    <hr/>
                    <p className='d-inline-block'><b>AR:</b> <NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>ARDOH:</b> <NumberSpan number={accountReceivableData.get("ardohFigure")} money={false} /></p> = <NumberSpan number={accountReceivableData.get("perDayAmountFigure")} money={true} /> <b>Per Day</b>
                </div>
                <div className='mt-2 mb-3'>
                    <p className='mb-1'>Account Receiable (AR): <NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true}/></p>
                    <p className='mb-1'>Sale / Revenue: <NumberSpan number={accountReceivableData.get("saleRevenueFigure")} money={true}/></p>
                </div>
            </div>
            <div className='col-4'>
            <div className='bg-custom-lgreen p-1 text-light text-center'><h5>Account Payable</h5></div>
                <div style={{border: '1px solid'}} className='p-1 wc-card'>
                    <p className='d-inline-block'><b>AP:</b> <NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>CoGS:</b> <NumberSpan number={accountPayableData.get("cogs")} money={true} /></p> x <span className='text-primary'>{WORKING_CAPITAL_DAYS}</span> = <NumberSpan number={accountPayableData.get("apdohFigure")} money={false} /> <b>APDOH</b>
                    <hr/>
                    <p className='d-inline-block'><b>AP:</b> <NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>APDOH:</b> <NumberSpan number={accountPayableData.get("apdohFigure")} money={false} /></p> = <NumberSpan number={accountPayableData.get("perDayAmountFigure")} money={true} /> <b>Per Day</b>
                </div>
                <div className='mt-2 mb-3'>
                    <p className='mb-1'>Account Payable (AP): <NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true}/></p>
                    <p className='mb-1'>Cost of Good Sold: <NumberSpan number={accountPayableData.get("cogs")} money={true}/></p>
                </div>
            </div>
            {inventoryData.has("inventoryFigure") && <div className='col-4'>
            <div className='bg-custom-lgreen p-1 text-light text-center'><h5>Inventory</h5></div>
                <div style={{border: '1px solid'}} className='p-1 wc-card'>
                    <p className='d-inline-block'><b>Inventory:</b> <NumberSpan number={inventoryData.get("inventoryFigure")} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>CoGS:</b> <NumberSpan number={inventoryData.get("cogs")} money={true} /></p> x <span className='text-primary'>{WORKING_CAPITAL_DAYS}</span> = <NumberSpan number={inventoryData.get("invdohFigure")} money={false} /> <b>INVDOH</b>
                    <hr/>
                    <p className='d-inline-block'><b>Inventory:</b> <NumberSpan number={inventoryData.get("inventoryFigure")} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>INVDH:</b> <NumberSpan number={inventoryData.get("invdohFigure")} money={false} /></p> = <NumberSpan  number={inventoryData.get("perDayAmountFigure")} money={true}/> <b>Per Day</b>
                </div>
                <div className='mt-2 mb-3'>
                    <p className='mb-1'>Inventory (INV): <NumberSpan number={inventoryData.get("inventoryFigure")} money={true}/></p>
                    <p className='mb-1'>Cost of Good Sold: <NumberSpan number={inventoryData.get("cogs")} money={true}/></p>
                </div>
            </div>}
            <div className='col-12 mb-3'>
                <div className='bg-custom-lgreen mb-2 text-center p-2'>
                    <h4 className='text-light'>Cash Flow Analysis Metric (Gain/Benefit/Opportunity)</h4>
                </div>
                <div className='row justify-content-between'>
                    <div className='col-4'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr >
                                    <th style={{background: '#cdcdcd'}}>Reduction of AR Days</th>
                                    <th style={{background: '#cdcdcd'}}>Gain in Cash-Flow</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    analysisDays.map(day => {
                                        return (
                                            <tr>
                                                <td>{day}</td>
                                                <td><NumberSpan number={accountReceivableData.get("perDayAmountFigure") * day} money={true} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='col-4'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr >
                                    <th style={{background: '#cdcdcd'}}>Increase AP Days</th>
                                    <th style={{background: '#cdcdcd'}}>Gain in Cash-Flow</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    analysisDays.map(day => {
                                        return (
                                            <tr>
                                                <td>{day}</td>
                                                <td><NumberSpan number={accountPayableData.get("perDayAmountFigure") * day} money={true} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {inventoryData.has("inventoryFigure") && <div className='col-4'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr >
                                    <th style={{background: '#cdcdcd'}}>Reduction of Inv Days</th>
                                    <th style={{background: '#cdcdcd'}}>Gain in Cash-Flow</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    analysisDays.map(day => {
                                        return (
                                            <tr>
                                                <td>{day}</td>
                                                <td><NumberSpan number={inventoryData.get("perDayAmountFigure") * day} money={true} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>}
                </div>            
            </div>
            <div className='col-12 mb-3'>
                <div className='bg-custom-lgreen mb-2 text-center p-2 d-none'>
                    <h4 className='text-light'>Cash Flow Analysis Metric (Gain/Benefit/Opportunity)</h4>
                </div>
                <p className='text-danger font-weight-bold'>**Using 5 Day as the benchmark</p>
                <div className='row justify-content-between'>
                    <div className='col-4'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>New Cash Flow/Bal:</td>
                                    <td><NumberSpan number={accountReceivableData.get("perDayAmountFigure") * 5} money={true} /></td>
                                </tr>
                                <tr>
                                    <th>Scenario If</th>
                                    <th>Rate/Yield</th>
                                    <th>Credit/Gain</th>
                                </tr>
                                {
                                    rates.map(r => {
                                        return (
                                            <tr>
                                                <td>{r.scenario}</td>
                                                <td>{r.rate || 0}%</td>
                                                <td><NumberSpan number={(accountReceivableData.get("perDayAmountFigure") * 5) * r.rate / 100} money={true} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='col-4'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>New Cash Flow/Bal:</td>
                                    <td><NumberSpan number={accountPayableData.get("perDayAmountFigure") * 5} money={true} /></td>
                                </tr>
                                <tr>
                                    <th>Scenario If</th>
                                    <th>Rate/Yield</th>
                                    <th>Credit/Gain</th>
                                </tr>
                                {
                                    rates.map(r => {
                                        return (
                                            <tr>
                                                <td>{r.scenario}</td>
                                                <td>{r.rate || 0}%</td>
                                                <td><NumberSpan number={(accountPayableData.get("perDayAmountFigure") * 5) * r.rate / 100} money={true} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {inventoryData.has("inventoryFigure") && <div className='col-4'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>New Cash Flow/Bal:</td>
                                    <td><NumberSpan number={inventoryData.get("perDayAmountFigure") * 5} money={true} /></td>
                                </tr>
                                <tr>
                                    <th>Scenario If</th>
                                    <th>Rate/Yield</th>
                                    <th>Credit/Gain</th>
                                </tr>
                                {
                                    rates.map(r => {
                                        return (
                                            <tr>
                                                <td>{r.scenario}</td>
                                                <td>{r.rate || 0}%</td>
                                                <td><NumberSpan number={(inventoryData.get("perDayAmountFigure") * 5) * r.rate / 100} money={true} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>

            <div className='col-12'>
                <div className='bg-secondary mb-2 text-center p-2'>
                    <h4 className='text-light'>Cash Conversion Cycle Analysis</h4>
                </div>
                <div className='table-responsive'>
                    <table className='table  table-striped table-dark'>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='text-center'>Cash +/-</th>
                                <th className='text-center'>Total $ WC</th>
                                <th className='text-center'>DAYS</th>
                                <th className='text-center'>WC $ PER DAY</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>RECEIVABLES:</td>
                                <td>USE(add)</td>
                                <td><NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true} /></td>
                                <td><NumberSpan number={accountReceivableData.get("days")} money={false} /></td>
                                <td><NumberSpan number={accountReceivableData.get("perDayAmountFigure")} money={true} /></td>
                            </tr>
                            <tr>
                                <td>INVENTORY:</td>
                                <td>USE(add)</td>
                                <td><NumberSpan number={inventoryData.get("inventoryFigure")} money={true} /></td>
                                <td><NumberSpan number={inventoryData.get("days")} money={false} /></td>
                                <td><NumberSpan number={inventoryData.get("perDayAmountFigure")} money={true} /></td>
                            </tr>
                            <tr>
                                <td>PAYABLE:</td>
                                <td>SOURCE(subtract)</td>
                                <td><NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true} /></td>
                                <td><NumberSpan number={accountPayableData.get("days")} money={false} /></td>
                                <td><NumberSpan number={accountPayableData.get("perDayAmountFigure")} money={true} /></td>
                            </tr>
                            <tr>
                                <td>TOTAL:</td>
                                <td></td>
                                <td><NumberSpan number={workingCapitalResult.total.get("total_wc")} money={true} /></td>
                                <td><NumberSpan number={workingCapitalResult.total.get("wc_days")} money={false} /></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <div className='row justify-content-between'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className='outer-slide-container'>
                            <div className='slide-content-container arm'>
                                <h6 className='text-center font-weight-bolder main-title'>Account Receivable Management</h6>
                                <div className='slide-content'>
                                    <p className='title'>Account Receivables Days On Hand:</p>
                                    <p className='item-cnt'><label>AR:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(accountReceivableData.get("accountReceivableFigure") || 0)} /></p>
                                    <div className='d-flex divider-matrix'>
                                        <div className='divider'></div>
                                        <span>X</span>
                                        <span>{WORKING_CAPITAL_DAYS}</span>
                                        <span>=</span>
                                        <p><input type='text' disabled readOnly value={(accountReceivableData.get("ardohFigure") || 0)} /> <span>ARDOH</span></p>
                                    </div>
                                    <p className='item-cnt'><label>SALES:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(accountReceivableData.get("saleRevenueFigure") || 0)}/></p>

                                    <hr style={{border: '2px dotted black'}}/>
                                    <p className='item-cnt'><label>AR:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(accountReceivableData.get("accountReceivableFigure") || 0)} /></p>
                                    <div className='d-flex divider-matrix'>
                                        <div className='divider'></div>
                                        <span>=</span>
                                        <p><input type='text' disabled readOnly  value={CommonService.formatToUSMoney(accountReceivableData.get("perDayAmountFigure") || 0)}/> <span>$PER DAY</span></p>
                                    </div>
                                    <p className='item-cnt'><label>ARDOH:</label><input type='text' disabled='true' readOnly value={(accountReceivableData.get("ardohFigure") || 0)} /></p>
                                    <p className='mb-0 desclaimer'>AR Management Priorities / Concerns:</p>
                                    <div className='comment'>
                                        <ol>
                                            <li>It takes your company <span>{accountReceivableData.get("ardohFigure") || 0}</span> days on average to collect <span>$1.00</span> of its Receivables.</li>
                                            <li>Your company would increase its cash balance by <NumberSpan number={accountReceivableData.get("perDayAmountFigure")} money={true} /> for every day Receivables days are reduced.</li>
                                            <li>Reducing your <NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true} /> in Account Receivables would mean an increase in cash balances = <span>Opportunity</span>.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className='outer-slide-container'>
                                <div className='slide-content-container apm'>
                                    <h6 className='text-center font-weight-bolder main-title'>Accounts Payable Management</h6>
                                    <div className='slide-content'>
                                        <p className='title'>Account Payable Days On Hand:</p>
                                        <p className='item-cnt'><label>AP:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(accountPayableData.get("accountPayableFigure") || 0)} /></p>
                                        <div className='d-flex divider-matrix'>
                                            <div className='divider'></div>
                                            <span>X</span>
                                            <span>{WORKING_CAPITAL_DAYS}</span>
                                            <span>=</span>
                                            <p><input type='text' disabled readOnly value={(accountPayableData.get("apdohFigure") || 0)} /> <span>APDOH</span></p>
                                        </div>
                                        <p className='item-cnt'><label>CoGS:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(accountPayableData.get("cogs") || 0)}/></p>

                                        <hr style={{border: '2px dotted black'}}/>
                                        <p className='item-cnt'><label>AP:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(accountPayableData.get("accountPayableFigure") || 0)} /></p>
                                        <div className='d-flex divider-matrix'>
                                            <div className='divider'></div>
                                            <span>=</span>
                                            <p><input type='text' disabled readOnly  value={CommonService.formatToUSMoney(accountPayableData.get("perDayAmountFigure") || 0)}/> <span>$PER DAY</span></p>
                                        </div>
                                        <p className='item-cnt'><label>APDOH:</label><input type='text' disabled='true' readOnly value={(accountPayableData.get("apdohFigure") || 0)} /></p>
                                        <p className='mb-0 desclaimer'>AP Management Priorities / Concerns:</p>
                                        <div className='comment'>
                                            <ol>
                                                <li>It takes your company <span>{accountPayableData.get("apdohFigure") || 0}</span> days on average to pay <span>$1.00</span> of its Payables.</li>
                                                <li>Your company would increase its cash balance by <NumberSpan number={accountPayableData.get("perDayAmountFigure")} money={true} /> for every day Payables days are increased.</li>
                                                <li>The key is paying on the exact day the payment is due, and not before or after.</li>
                                                <li>Reducing your <NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true} /> in Account Payables would mean an increase in cash balances = <span>Opportunity</span>.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    {inventoryData.has("inventoryFigure") && <div className='col-lg-4 col-md-6 col-12'>
                        <div className='outer-slide-container'>
                            <div className='slide-content-container invm'>
                                <h6 className='text-center font-weight-bolder main-title'>Inventory Management</h6>
                                <div className='slide-content'>
                                    <p className='title'>Inventory Days On Hand:</p>
                                    <p className='item-cnt'><label>INV:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(inventoryData.get("inventoryFigure"))} /></p>
                                    <div className='d-flex divider-matrix'>
                                        <div className='divider'></div>
                                        <span>X</span>
                                        <span>{WORKING_CAPITAL_DAYS}</span>
                                        <span>=</span>
                                        <p><input type='text' disabled readOnly value={(inventoryData.get("invdohFigure"))} /> <span>INVDOH</span></p>
                                    </div>
                                    <p className='item-cnt'><label>CoGS:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(inventoryData.get("cogs"))}/></p>

                                    <hr style={{border: '2px dotted black'}}/>
                                    <p className='item-cnt'><label>INV:</label><input type='text' disabled='true' readOnly value={CommonService.formatToUSMoney(inventoryData.get("inventoryFigure"))} /></p>
                                    <div className='d-flex divider-matrix'>
                                        <div className='divider'></div>
                                        <span>=</span>
                                        <p><input type='text' disabled readOnly  value={CommonService.formatToUSMoney(inventoryData.get("perDayAmountFigure"))}/> <span>$PER DAY</span></p>
                                    </div>
                                    <p className='item-cnt'><label>INVDOH:</label><input type='text' disabled='true' readOnly value={(inventoryData.get("invdohFigure"))} /></p>
                                    <p className='mb-0 desclaimer'>Inventory Management Priorities / Concerns:</p>
                                    <div className='comment'>
                                        <ol>
                                            <li>Your company holds its inventory for <span>{inventoryData.get("invdohFigure")}</span> on average before its sold.</li>
                                            <li>Your company would increase its cash balance by <NumberSpan  number={inventoryData.get("perDayAmountFigure")} money={true}/> for every day Inventory days are reduced.</li>
                                            <li>Reducing your <NumberSpan number={inventoryData.get("inventoryFigure")} money={true} /> in Inventory would mean an increase in cash balances = <span>Opportunity</span>.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>

        <div className='row mt-5'>
            <div className='col-12'>
                <div className='row justify-content-center'>
                    <div className='col-lg-3 col-md-3 col-12 info-acct'>
                        <img src="/assets/images/receivable-ccc.png" alt="account-receivable-info" />
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='standalone-content w-100 arm'>
                            <p className='title'>Accounts Receivable Management</p>
                            <p className='sub-title'>Accounts Receivable Days on Hand</p>
                            <div className='label-value'>
                                <label>A/R:</label>
                                <p className='input-label'><NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true} /></p>
                            </div>

                            <div className='d-flex divider-matrix'>
                                <div className='divider'></div>
                                <span>X</span>
                                <span className='ml-0'>{WORKING_CAPITAL_DAYS}</span>
                                <span className='ml-0'>=</span>
                                <p className='input-label'><NumberSpan number={accountReceivableData.get("ardohFigure")} money={false} /></p> <span>ARDOH</span>
                            </div>
                            <div className='label-value'>
                                <label>SALES:</label>
                                <p className='input-label'><NumberSpan number={accountReceivableData.get("saleRevenueFigure")} money={true} /></p>
                            </div>

                            <div className='mt-1 mb-1'></div>

                            <div className='label-value mt-4'>
                                <label>A/R:</label>
                                <p className='input-label'><NumberSpan number={accountReceivableData.get("accountReceivableFigure")} money={true} /></p>
                            </div>

                            <div className='d-flex divider-matrix'>
                                <div className='divider'></div>
                                <span>=</span>
                                <p className='input-label'><NumberSpan number={(accountReceivableData.get("perDayAmountFigure"))} money={true} /></p> <span>PER DAY</span>
                            </div>
                            <div className='label-value'>
                                <label>ARDOH:</label>
                                <p className='input-label'><NumberSpan number={accountReceivableData.get("ardohFigure")} money={false} /></p>
                            </div>
                        </div>
                        <div className='mt-3 desclaimer'>
                            <p>What this means for {clientName}</p>
                            <ul>
                                <li>It takes {clientName} <NumberSpan number={accountReceivableData.get("ardohFigure")} money={false} /> days on average to collect $1 of its receivables.</li>
                                <li>{clientName} would be able to increase its cash balance by <NumberSpan number={(accountReceivableData.get("perDayAmountFigure"))} money={true} /> for every decrease of one day to its ARDOH.</li>
                                <li>Through its Treasury Management services, Regions can help clients achieve this goal and shorten its Cash Conversion Cycle.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-12 clogo'>
                        {clientLogo && <img src={clientLogo} alt="client logo" />}
                    </div>
                </div>
            </div>

            <div className='col-12 mt-4'>
                <div className='row justify-content-center'>
                    <div className='col-lg-3 col-md-3 col-12 info-acct'>
                    <img src="/assets/images/payable-ccc.png" alt="account-payable-info" />
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='standalone-content w-100 apm'>
                            <p className='title'>Accounts Payable Management</p>
                            <p className='sub-title'>Accounts Payable Days on Hand</p>
                            <div className='label-value'>
                                <label>A/P:</label>
                                <p className='input-label'><NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true} /></p>
                            </div>

                            <div className='d-flex divider-matrix'>
                                <div className='divider'></div>
                                <span>X</span>
                                <span className='ml-0'>{WORKING_CAPITAL_DAYS}</span>
                                <span className='ml-0'>=</span>
                                <p className='input-label'><NumberSpan number={accountPayableData.get("apdohFigure")} money={false} /></p> <span>APDOH</span>
                            </div>
                            <div className='label-value'>
                                <label>CoGS:</label>
                                <p className='input-label'><NumberSpan number={accountPayableData.get("cogs")} money={true} /></p>
                            </div>

                            <div className='mt-1 mb-1'></div>

                            <div className='label-value mt-4'>
                                <label>A/P:</label>
                                <p className='input-label'><NumberSpan number={accountPayableData.get("accountPayableFigure")} money={true} /></p>
                            </div>

                            <div className='d-flex divider-matrix'>
                                <div className='divider'></div>
                                <span>=</span>
                                <p className='input-label'><NumberSpan number={(accountPayableData.get("perDayAmountFigure"))} money={true} /></p> <span>PER DAY</span>
                            </div>
                            <div className='label-value'>
                                <label>APDOH:</label>
                                <p className='input-label'><NumberSpan number={accountPayableData.get("apdohFigure")} money={false} /></p>
                            </div>
                        </div>
                        <div className='mt-3 desclaimer'>
                            <p>What this means for {clientName}</p>
                            <ul>
                                <li>{clientName} has <NumberSpan number={accountPayableData.get("apdohFigure")} money={false} /> days on average to pay $1 of its payables.</li>
                                <li>{clientName} would be able to increase its cash balance by <NumberSpan number={(accountPayableData.get("perDayAmountFigure"))} money={true} /> for every increase of one day to its APDOH.</li>
                                <li>Through its Treasury Management services, Regions can help clients achieve this goal and shorten its Cash Conversion Cycle.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-12 clogo'>
                        {clientLogo && <img src={clientLogo} alt="client logo" />}
                    </div>
                </div>
            </div>

            { inventoryData.has("inventoryFigure") && <div className='col-12 mt-4'>
                <div className='row justify-content-center'>
                    <div className='col-lg-3 col-md-3 col-12 info-acct'>
                    <img src="/assets/images/inventory-ccc.png" alt="account-inventory-info" />
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='standalone-content w-100 inv'>
                            <p className='title'>Inventory Management</p>
                            <p className='sub-title'>Inventory Days on Hand</p>
                            <div className='label-value'>
                                <label>INV:</label>
                                <p className='input-label'><NumberSpan number={inventoryData.get("inventoryFigure")} money={true} /></p>
                            </div>

                            <div className='d-flex divider-matrix'>
                                <div className='divider'></div>
                                <span>X</span>
                                <span className='ml-0'>{WORKING_CAPITAL_DAYS}</span>
                                <span className='ml-0'>=</span>
                                <p className='input-label'><NumberSpan number={inventoryData.get("invdohFigure")} money={false} /></p> <span>INVDOH</span>
                            </div>
                            <div className='label-value'>
                                <label>CoGS:</label>
                                <p className='input-label'><NumberSpan number={inventoryData.get("cogs")} money={true} /></p>
                            </div>

                            <div className='mt-1 mb-1'></div>

                            <div className='label-value mt-4'>
                                <label>INV:</label>
                                <p className='input-label'><NumberSpan number={inventoryData.get("inventoryFigure")} money={true} /></p>
                            </div>

                            <div className='d-flex divider-matrix'>
                                <div className='divider'></div>
                                <span>=</span>
                                <p className='input-label'><NumberSpan number={(inventoryData.get("perDayAmountFigure"))} money={true} /></p> <span>PER DAY</span>
                            </div>
                            <div className='label-value'>
                                <label>INVDOH:</label>
                                <p className='input-label'><NumberSpan number={inventoryData.get("invdohFigure")} money={false} /></p>
                            </div>
                        </div>
                        <div className='mt-3 desclaimer'>
                            <p>What this means for {clientName}</p>
                            <ul>
                                <li>{clientName} holds its inventory for <NumberSpan number={inventoryData.get("invdohFigure")} money={false} /> days on average before it is sold.</li>
                                <li>{clientName} would be able to increase its cash balance by <NumberSpan number={(inventoryData.get("perDayAmountFigure"))} money={true} /> for every decrease of one day to its INVDOH.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-12 clogo'>
                        {clientLogo && <img src={clientLogo} alt="client logo" />}
                    </div>
                </div>
            </div> }
        </div>
    </>
}

export default WCRatio;