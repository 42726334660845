import axios, { AxiosError } from 'axios';
import { COMMON_UPLOAD_URL, FINANCIAL_ANALYSIS_NEST_URL, FINANCIAL_ANALYSIS_URL, PPT_CLIENT_URL } from '../config/api.config';

export const FinanCialAnalysis = {
    async generateWCDataRatio(data) {
        try {
            const formData = new FormData();
            // formData.append("clogo", data.clogo);
            // delete data.clogo;
            data.clogo = data.clientFileData.fileLink;
            formData.append("config", JSON.stringify(data));
            formData.append('fileType', data.fileType);
            const response = await axios.post(`${FINANCIAL_ANALYSIS_URL}/wc`, formData, 
            {
                withCredentials: true
            });
            console.log(response.data);
            return {error: false, message: "Working Capital Ratio file processed.", data: response.data};
        } catch(e) {
            if(e instanceof AxiosError) {
                return {error: true, message: e.response.data.message, data: e.response.data};
            }
            return {error: true, message: "Internal Server Error"};
        }
    },

    async addPPTClient(clientData) {
        try {
            const response = await axios.post(`${PPT_CLIENT_URL}`, clientData);
            return {error: false, message: response.data.message, data: response.data};
        } catch(e) {
            if(e instanceof AxiosError) {
                return {error: true, message: e.response.data.message, data: e.response.data};
            }
            return {error: true, message: "Internal Server Error"};
        }
    },

    async addClientLogo(clientLogo) {
        try {
            const formData = new FormData();
            formData.append("file", clientLogo);
            formData.append("folder", "clients/logo");
            const response = await axios.post(COMMON_UPLOAD_URL, formData);
            return {error: false, message: response.data.message, data: response.data.data};
        } catch(e) {
            if(e instanceof AxiosError) {
                return {error: true, message: e.response.data.message, data: e.response.data};
            }
            return {error: true, message: "Internal Server Error"};
        }
    },
    /**
     * 
     * @param {{company: string, accountReceivable: Map ,accountPayable: Map,inventory: Map,total: Map,currentRatio: Map,assetsTurnoverRatio: Map, loanIntRate: number, investmentIntRate:  number,creditRate:number, loanBalance:number,clientId: string }} param0 
     */
    async saveFinancialAnalysisData({
        company,
        accountReceivable,
        accountPayable,
        inventory,
        total,
        currentRatio,
        assetsTurnoverRatio,
        quickRatio,
        loanIntRate,
        investmentIntRate,
        creditRate,
        loanBalance,
        clientId
    }) {
        try {
        const financialAnalysisData = {
            company: company,
            client_id:clientId,
            inputs: {
              total_sale_revenue: accountReceivable.get("saleRevenueFigure"),
              cogs: accountPayable.get("cogs"),
              account_receivables: accountReceivable.get("accountReceivableFigure"),
              account_payables: accountPayable.get("accountPayableFigure"),
              loan_int_rate: loanIntRate,
              investment_int_rate: investmentIntRate,
              credit_rate: creditRate,
              inventory: inventory.get("inventoryFigure"),
              loan_balance: loanBalance,
              current_assets: currentRatio.get('currentAssets'),
              current_liabilities: currentRatio.get('currentLiabilities'),
              total_assets: assetsTurnoverRatio.get("totalAssets")
            },
            working_capital: {
              account_receivable: {
                wc_days: accountReceivable.get("days"),
                account_receivable_figure: accountReceivable.get("accountReceivableFigure"),
                sale_revenue_figure: accountReceivable.get("saleRevenueFigure"),
                ardoh_figure: accountReceivable.get("ardohFigure"),
                days: accountReceivable.get("days"),
                per_day_amount_figure: accountReceivable.get("perDayAmountFigure")
              },
              account_payable: {
                wc_days: accountPayable.get("days"),
                account_payable_figure: accountPayable.get("accountPayableFigure"),
                cogs: accountPayable.get("cogs"),
                apdoh_figure: accountPayable.get("apdohFigure"),
                days: accountPayable.get("days"),
                per_day_amount_figure: accountPayable.get("perDayAmountFigure")
              },
              inventory: {
                wc_days: inventory.get("days"),
                inventory_figure: inventory.get("inventoryFigure"),
                cogs: inventory.get("cogs"),
                invdoh_figure: inventory.get("invdohFigure"),
                days: inventory.get("days"),
                per_day_amount_figure: inventory.get("perDayAmountFigure")
              },
              total: {
                total_wc: total.get("total_wc"),
                total_wc_avg: total.get("total_wc_avg"),
                total_wc_avg_ar_ap: total.get("total_wc_avg_ar_ap"),
                total_avg_per_day: total.get("total_avg_per_day"),
                total_avg_per_day_ar_ap: total.get("total_avg_per_day_ar_ap"),
                wc_days: total.get("wc_days"),
                wc_days_less_ap: total.get("wc_days_less_ap")
              }
            },
            liquidity_ratio: {
              current_ratio: {
                current_assets: currentRatio.get('currentAssets'),
                current_liabilities: currentRatio.get('currentLiabilities'),
                current_ratio: currentRatio.get('currentRatio'),
              },
              assets_turnover_ratio: {
                total_sale_revenue: assetsTurnoverRatio.get('totalSaleRevenue'),
                total_assets: assetsTurnoverRatio.get("totalAssets"),
                assets_turnover_ratio: assetsTurnoverRatio.get('assetsTurnoverRatio')
              },
              quick_ratio: {
                current_assets: quickRatio.get('currentAssets'),
                current_liabilities: quickRatio.get('currentLiabilities'),
                quick_ratio: quickRatio.get('quickRatio')
              }
            }
          };

          console.log(financialAnalysisData, "financialAnalysisData");

          const response = await axios.post(FINANCIAL_ANALYSIS_NEST_URL, financialAnalysisData);
          return {error: false, message: response.data.message, data: response.data.data};
        }catch(e) {
            if(e instanceof AxiosError) {
                return {error: true, message: e.response.data.message, data: e.response.data};
            }
            return {error: true, message: "Internal Server Error"};
        }
    }
}