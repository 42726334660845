import React, { useEffect, useState, useRef } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { BASE_API_URL } from "../../config";

const BankersList = () => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['XSRF-TOKEN']);
    const dataFetchedRef = useRef(false);
    const history = useNavigate();

    let response = "";

    useEffect(() => {
        setLoading(true);
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetch(`${BASE_API_URL}/api/bankers`, {
            method: 'GET',
            //   cache: 'no-cache',
            // mode: 'no-cors',
            // redirect: 'follow',
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            setGroups(data);
            setLoading(false);
        }).catch(function (error) {
            console.log("Error :" + error);
            console.log("Response Code: " + response.status);
            history('/');
        })
    }, []);

    const remove = async (id) => {
        /* let response = await fetch(`/api/bankers/${id}`, {
           method: 'DELETE',
           headers: {
             'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
             'Accept': 'application/json',
             'Content-Type': 'application/json'
           },
           credentials: 'include'
         }).then(() => {	  
           let updatedGroups = [...groups].filter(i => i.id !== id);
           setGroups(updatedGroups);
         }).catch(err => {
           console.log(err.response.data);
         });*/

        let response = await fetch(BASE_API_URL + '/api/bankers/' + id, {
            method: 'DELETE',
            headers: {
                'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if(response.status == 403) {
            alert("Not Authorized to delete an baker!!!!");
        }

        if(response.status === 204) {
            let updatedGroups = [...groups].filter(i => i.id !== id);
            console.log("I am here...");
            setGroups(updatedGroups);
        }

        if(response.status != 200) {
            console.log("Error: " + response.message);
        }
    }

    if(loading) {
        return <p>Loading...</p>;
    }

    const groupList = groups.map(group => {
        let activationStatus = "Not Defined";

        if (group.active === 'N') {
            activationStatus = <a className="btn btn-success btn-sm" tag={Link} href={"/banker/activate/" + group.id}>Activate</a>
        } else if (group.active === 'P') {
            activationStatus = <span className="btn-yellow-custom">Pending</span>
        } else if (group.active === 'Y') {
            activationStatus = <span className="btn-green-custom">Activated</span>
        } else {
            activationStatus = "";
        }

        return (
            <tr key={group.id}>
                <th scope="row">{group.firstName} {group.lastName}</th>
                <td>{group.email}</td>
                <td>{group.title}</td>
                <td>{group.bankingVetical}</td>
                <td>{group.branchName}</td>
                <td>{group.department}</td>
                <td>{activationStatus}</td>
                <td className="text-center">
                    <a className="btn btn-success btn-sm mb-2 mt-2" tag={Link} href={"/banker/" + group.id}>Edit</a>
                    <a className="btn btn-danger btn-sm ml-1 mb-2 mt-2" href="#" onClick={() => remove(group.id)}>Delete</a>
                </td>
            </tr>
          );
    });

    return (
            <>
                <div id="content" className="p-2">
                    <div className="form-submission">
                        <div className="col-lg-12">
                            <div className="card-header mb-4">
                                <div className="row">
                                    <div className="col text-left">
                                        <h3>Manage Bankers</h3>
                                    </div>
                                    <div className="col text-right">
                                        <a className="btn btn-success btn-success-href" tag={Link} href="/banker/import">Upload</a> {/* Upload Bankers */}
                                        <a className="btn btn-success btn-success-href ml-3" tag={Link} href="/banker/add">Add Banker</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Domain</th>
                                                <th scope="col">Branch</th>
                                                <th scope="col">Department</th>
                                                <th scope="col">Active</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { groupList }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>        
    );
};

export default BankersList;