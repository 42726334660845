export const BASE_API_URL = process.env.REACT_APP_API_ROOT;
export const BASE_WEB_ROOT = process.env.REACT_APP_WEB_ROOT;
export const BASE_API_PORT = process.env.REACT_APP_PORT;
export const BASE_SAAS_API_URL = process.env.REACT_APP_SAAS_API;

// Working capital PPT generate path
export const FINANCIAL_ANALYSIS_URL = `${BASE_API_URL}/api/financial-analysis`;

export const FINANCIAL_ANALYSIS_NEST_URL = `${BASE_SAAS_API_URL}/financial-analysis`;

// SaaS server Path
// Client of PPT
export const PPT_CLIENT_URL = `${BASE_SAAS_API_URL}/ppt-client`;
// Run History
export const RUN_HISTORY_URL = `${BASE_SAAS_API_URL}/parser-history`;
// Uplaod url
export const COMMON_UPLOAD_URL = `${BASE_SAAS_API_URL}/common/upload`;

