import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route, Routes, withRouter } from "react-router-dom";
import { authProtectedRoutes } from "./routes/";
import Layout from "./components/Layout/";
import ProtectedRoute from "./routes/route";
import './App.css';
//import './_assets/notifications.css';

const App = () => {
  return (
    <Router>
      <Routes>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              route.layout !== false ? (
                <Layout>
                  <route.component />
                </Layout>
              ) : (
                <route.component />
              )
            }
          />
        ))}
      </Routes>
    </Router>
  );
};


/*const App = () => {
  return (
    <Router>
      <Routes>
        {authProtectedRoutes.map((route, idx) => (
          <ProtectedRoute
            key={idx}
            path={route.path}
            component={route.component}
            layout={route.layout}
            exact={route.exact}
          />
        ))}
      </Routes>
    </Router>
  );
};*/

export default App;