import React, { Component } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import StepOne from '../../components/GeneratePowerpoint/StepOne';
import StepTwo from '../../components/GeneratePowerpoint/StepTwo';
import StepThree from '../../components/GeneratePowerpoint/StepThree';
import StepFour from '../../components/GeneratePowerpoint/StepFour';
import StepFive from '../../components/GeneratePowerpoint/StepFive';
// import StepSix from '../../components/GeneratePowerpoint/StepSix';
import StepSeven from '../../components/GeneratePowerpoint/StepSeven';
import StepEight from '../../components/GeneratePowerpoint/StepEight';
import StepNine from '../../components/GeneratePowerpoint/StepNine';


export default class MultiStepForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            isinternalview: false,
            isexternalview: false,
            rbemail: '',
            rbtitle: '',
            rbdepartment: '',
            rbfirstname: '',
            rblastname: '',
            rbmobile: '',
            cfirstname: '',
            clastname: '',
            ctitle: '',
            cdepartment: '',
            cemail: '',
            cmobile: '',
            clogo: '',
            clogoUrl: '',
            fileType: '',
            logoPreview: '',
            bankers: new Set(),
            bankersId: [],
            statementtype: 'AA',
            // Working Capital
            workingCapital: false,
            company: '',
            totalSaleRevenue: '',
            cogs: '',
            accountReceivables: '',
            accountPayables: '',
            loanIntRate: '',
            investmentIntRate: '',
            inventory: '',
            // Working Capital
            // Choose Graphical / Chart View
            ispieview: false,
            issmartview: false,
            isstatsview: false,
            isrecomendedview: false,
            issmartartview: false,
            issmartsrcvview: false,
            isaccountview: false,
            workingCapitalWithInventory: false,
            workingCapitalWithoutInventory: false,
            workingCapitalCCCDateWithInventory: false,
            workingCapitalCCCDateWithoutInventory: false,
            workingCapitalCCCAmountWithInventory: false,
            workingCapitalCCCAmountWithoutInventory: false,
            // End of Graphical and Chart View
            selectedbankers: [],
            pname: 'Treasury and Liquidity Management Presentation'
        };
    }

    componentDidMount() {
        this.setState({
            rbfirstname: this.props.tuser?.given_name,
            rblastname: this.props.tuser?.family_name,
            rbemail: this.props.tuser?.email
        });
    }

    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    handleSelectedBankers = (selectedbankers) => {
        this.setState({
            selectedbankers: selectedbankers
        });
    }

 
    handleChange = input => e => {
        const checkboxControllers = ['isstatsview', 'issmartview', 'ispieview', 'isrecomendedview', 'issmartartview',
            'issmartsrcvview', 'isaccountview', 'isinternalview', 'isexternalview',
        'workingCapitalWithInventory', 'workingCapitalCCCAmountWithInventory', 'workingCapitalCCCAmountWithoutInventory',
            'workingCapitalCCCDateWithInventory', 'workingCapitalCCCDateWithoutInventory', 'workingCapitalWithoutInventory'];
        // if(input==='isstatsview' || input==='issmartview' || input==='ispieview' || input==='isinternalview' || input==='isexternalview'){
            if(input === 'clogo') {
                const files = e.target.files;
                if(!files.length) {
                    toastr.error("Please select a logo");
                    return;
                }
                const type = files[0].type;
                const regexp = new RegExp('image/*', 'i');
                if(!regexp.test(type)) {
                    toastr.error("Only jpg and png are allowed.");
                    return;
                }
                // Size for client logo
                if(files[0].size > 563200) {
                    toastr.error("Size must be below 550KB");
                    return;
                }
                const blob = new Blob([files[0]]);
                const url = URL.createObjectURL(blob, type);
                this.setState({
                    clogo: files[0],
                    logoPreview: url,
                    clogoUrl: url,
                    fileType: type
                });
                return;
            }
            if(input === 'clogourl') {
                this.setState({
                    clogo: e.target.value,
                    logoPreview: e.target.value,
                    clogoUrl: e.target.value,
                });
                // set the file type to the state from the url
                this.getFIleType(e.target.value);
                return;
            }
        if (checkboxControllers.includes(input)) {
            let keyToFalse = '';
            const changedObject = {[input]: e.target.checked};
            if (input === 'isinternalview' && e.target.checked) {
                keyToFalse = 'isexternalview';
            }
            if (input === 'isexternalview' && e.target.checked) {
                keyToFalse = 'isinternalview';
            }
            if (keyToFalse) {
                // External or internal changed
                changedObject[keyToFalse] = false;
            }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    ...changedObject
                }
            });
            // this.setState({ [input]: e.target.checked });
        } else {
            // Working capitals radio
            let value = e.target.value;
            if(input === 'workingCapital') {
                value = value === 'yes';
            }
            if(['totalSaleRevenue', 'cogs', 'accountReceivables', 'accountPayables', 'loanIntRate', 'investmentIntRate', 'inventory'].includes(input)) {
                // Check if the value is number
                if(isNaN(value)) {
                    return;
                }
            }
            this.setState({[input]: value});
        }
    }
    getFIleType = async (url) => {
        const res = await fetch(url, {method:'HEAD'});
        this.setState({
            fileType: res.headers.get('Content-Type')
        });
    }
    resetInputFromState = input => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                [input]: ''
            };
        });
    }

    resetHandler = this.resetInputFromState.bind(this);
    addBankerToState = banker => {
        const bankers = this.state.bankers;
        bankers.add(banker);
        const bankersId = this.state.bankersId;
        bankersId.push(banker.id);
        this.setState({
            bankers: bankers,
            bankersId:bankersId
        });
    }

    removeBanker = banker => {
        const bankers = this.state.bankers;
        const bankersId = this.state.bankersId.filter(b => b !== banker.id);
        bankers.delete(banker);
        console.log(bankers, bankersId, banker);
        this.setState({
            bankers: bankers,
            bankersId:bankersId
        });
    }

    render() {
        let userInfo = this.props.tuser;
        if (userInfo != null) 
        {
            const { step } = this.state;
            const { isinternalview, isexternalview,
                    rbfirstname, rblastname, rbemail, rbtitle, rbdepartment, rbmobile,
                    cfirstname, clastname, cemail, ctitle, cdepartment,  cmobile, clogo, logoPreview,clogoUrl,fileType,
                    bankers, bankersId,
                workingCapital, company, totalSaleRevenue, cogs, accountReceivables, accountPayables,
                loanIntRate, investmentIntRate, inventory,
                 ispieview, issmartview, isstatsview, isrecomendedview, isaccountview,
                issmartsrcvview, issmartartview,
                statementtype, selectedbankers, pname, 
                workingCapitalCCCAmountWithInventory,
                workingCapitalCCCAmountWithoutInventory,
                workingCapitalCCCDateWithInventory,
                workingCapitalCCCDateWithoutInventory,
                workingCapitalWithoutInventory,
                workingCapitalWithInventory } = this.state;
            const values = {
                isinternalview,
                isexternalview,
                rbfirstname,
                rblastname,    
                rbemail,
                rbtitle,
                rbdepartment,
                rbmobile,
    
                cfirstname,
                clastname,
                ctitle,
                cdepartment,
                cemail,
                cmobile,
                clogo,
                clogoUrl,
                fileType,
                logoPreview,
                bankers,
                bankersId,
                // Working capital
                workingCapital,
                company,
                totalSaleRevenue,
                cogs,
                accountReceivables,
                accountPayables,
                loanIntRate,
                investmentIntRate,
                inventory,
                // Working capital
                ispieview,
                issmartview,
                isstatsview,
                isrecomendedview, isaccountview, issmartsrcvview, issmartartview,
                statementtype,
                selectedbankers,
                pname,
                workingCapitalCCCAmountWithInventory,
                workingCapitalCCCAmountWithoutInventory,
                workingCapitalCCCDateWithInventory,
                workingCapitalCCCDateWithoutInventory,
                workingCapitalWithoutInventory,
                workingCapitalWithInventory,
                // Create an array of items of graph
                graphItems: [
                    {
                        image: "PieChartSample.JPG",
                        alt: "PieChartSample",
                        isSelected: ispieview
                    },
                    {
                        image: "SmartChartSample.JPG",
                        alt: "SmartChartSample",
                        isSelected: issmartview
                    },
                    {
                        image: "SummaryViewSample.JPG",
                        alt: "SummaryViewSample",
                        isSelected: isstatsview
                    },
                    {
                        image: "RecommendationViewSample.JPG",
                        alt: "RecommendationViewSample",
                        isSelected: isrecomendedview
                    },
                    {
                        image: "SmartProtectViewSample.JPG",
                        alt: "SmartProtectViewSample",
                        isSelected: issmartartview
                    },
                    {
                        image: "SmartServiceViewSample.JPG",
                        alt: "SmartServiceViewSample",
                        isSelected: issmartsrcvview
                    },
                    {
                        image: "AccountViewSample.JPG",
                        alt: "AccountViewSample",
                        isSelected: isaccountview
                    },
                    // Working capital
                    {
                        image: "WorkingCapitalCCCAmountWithInventory.JPG",
                        alt: "WorkingCapitalCCCAmount",
                        isSelected: workingCapitalCCCAmountWithInventory
                    },
                    {
                        image: "WorkingCapitalCCCAmountWithoutInventory.JPG",
                        alt: "WorkingCapitalCCCAmount",
                        isSelected: workingCapitalCCCAmountWithoutInventory
                    },
                    {
                        image: "WorkingCapitalCCCDateWithInventory.JPG",
                        alt: "WorkingCapitalCCCDate",
                        isSelected: workingCapitalCCCDateWithInventory
                    },
                    {
                        image: "WorkingCapitalCCCDateWithoutInventory.JPG",
                        alt: "WorkingCapitalCCCDate",
                        isSelected: workingCapitalCCCDateWithoutInventory
                    },
                    {
                        image: "WorkingCapitalMapWithoutInventory.JPG",
                        alt: "WorkingCapitalWithoutInventory",
                        isSelected: workingCapitalWithoutInventory
                    },
                    {
                        image: "WorkingCapitalMapWithInventory.JPG",
                        alt: "WorkingCapitalWithInventory",
                        isSelected: workingCapitalWithInventory
                    }
                ]
            }

            switch (step) {
                case 1:
                    return (
                        <StepOne
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 2:
                    /*if (step > 2) {
                        return null; // Do not render StepTwo if current step is greater than 2
                    }*/  

                    return (
                        <StepTwo
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 3:
                    return (
                        <StepThree
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 4:
                    return (
                        <StepFour
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 5:
                    return (
                        <StepFive
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleSelectedBankers={this.handleSelectedBankers}
                            addBankerToState={this.addBankerToState}
                            removeBanker={this.removeBanker}
                            values={values}
                        />
                    )
                case 6:
                //     return (
                //         <StepSix
                //             prevStep={this.prevStep}
                //             nextStep={this.nextStep}
                //             handleChange={this.handleChange}
                //             resetHandler={this.resetHandler}
                //             values={values}
                //         />
                //     )
                // case 7:
                    return (
                        <StepSeven
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                // case 8:
                case 7:
                    return (
                        <StepEight
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            values={values}
                        />
                    )
                // case 9:
                case 8:
                    return (
                        <StepNine
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                default:
                    // do nothing
            }
        } else {
            return <><p>No T User.</p></>
        }
    }
}
