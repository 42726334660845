import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
// import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
// import AppNavbar from '../../pages/AppNavbar';
// import { useCookies } from 'react-cookie';
// import axios from 'axios';
import { BASE_API_URL } from "../../config";
//import FilesWebViewer from '../FilesWebViewer/index';
import FileWebViewer from '../FilesWebViewer/FileWebViewer';
import { RunHistoryService } from '../../services';


let uploadedFileName;
let uploadedFileDownloadURL;
let uploadedFileType;
let processedFileName;
let mappingFileName;
let mappingFileURL;
let processedFileURL;

const StepNine = ({ prevStep, nextStep, handleChange, values }) => {

  // let configRequest = ;

  const [authenticated, setAuthenticated] = useState(false);
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [genFileURL, setGenFileURL] = useState(null);
  //const [genViewClick, setGenViewClick] = useState(null);
  //let fileViewerWindow = null;
  const history = useNavigate();

  let dataFetchedRef = useRef(false);

  useEffect(() => {
    // setLoading(true);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
      .then(response => response.text())
      .then(body => {
        if (body === '') {
          setAuthenticated(false);
          console.log("User not authenticated, redirecting the user to homepage");
          history('/');
        } else {
          setAuthenticated(true);
        }
        // setLoading(false);
      });
  }, [])

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await uploadFile(file);
      if(res.error) {
        // show the modal
        toastr.error('Could not generate the presentation. Please try again after some time.')
        // clean up the UI
        // such as disable the loader
        return;
      }
      const data = res.data;
      uploadedFileName = data.uploadedFileName;
      uploadedFileDownloadURL = data.uploadedFileDownloadURL;
      uploadedFileType = data.uploadedFileType;
      processedFileName = data.processedFileName;
      mappingFileName = data.mappingFileName;
      mappingFileURL = data.mappingFileURL;
      processedFileURL = data.processedFileURL;
      setLoading(false);
      setFile1(uploadedFileName);
      setGenFileURL(processedFileURL);
      // call the run history API -> this is a temporary fix, will be changed to call from the JAVA end
      const response = await RunHistoryService.addRunHistory({
        client_name: processedFileName.split(".")[0],
        ppt_file: processedFileURL,
        type: "analysis"
      });
      console.log(response, "RESPONSE");
    }catch(e) {
      // some modal

    }
  }

  const uploadFile = async file => {
    const {clogo, ...data} = values;
    const formData = new FormData();
    formData.append("file", file);
    if(clogo instanceof File) {
      formData.append('logo', clogo);
      // delete data['clogo'];
    } else {
      data.clogo = clogo;
    }
    formData.append("statementType", "AA");
    formData.append("config", JSON.stringify({...data}));
    formData.append('fileType', data.fileType);
    // send fetch along with cookies
    let response = await fetch(`${BASE_API_URL}/api/stmt/uploadFile`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    // server responded with http response != 200
    if (response.status !== 200)
      return {error: true, message: "Could Not Generate the PPT."};
    let json_response = await response.json();

    if (json_response.error === 1)
      return {error: true, message: json_response.message};

    return {error: false, message: "PPT generated successfully.", data: json_response};
  };

  const handleOnChange = e => {
    setFile(e.target.files[0]);
  };

  const handleViewClick = () => {
    const newWindow = window.open('', '_blank');
    newWindow.document.write('<html><head><title>File Viewer</title></head><body><div id="fileViewerRoot"></div></body></html>');
    newWindow.document.close();
    const fileViewerRoot = newWindow.document.getElementById('fileViewerRoot');
    ReactDOM.render(<FileWebViewer url={genFileURL} />, fileViewerRoot);
  };  

  //console.log("Updated state 9:", values); 

  return (
    <>
      <div id="content" className="p-2">
        <div className="form-submission">
            <div className="col-lg-12">
              <div className="card-header mb-4">
                  <h3>Upload Analysis Fees Statement</h3>
              </div>
              <div className="row">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="col-lg-12">
                    <div className="mb-3"><label htmlFor="file" className="form-label">Uplodad PDF File</label>
                      <input name="file" required type="file" className="file-input form-control" onChange={handleOnChange} />                        
                    </div>
                    <p className="p-button-holder">   
                      <button type="submit" className="btn btn-success-custom mr-3 text-center">Upload</button>
                      <button type="button" className="btn btn-success-custom text-center" tag={Link} to="/bankers">Cancel</button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
        </div>
        <br />
        <div className="form-submission">
            <div className="col-lg-12">
              <div className="card-header mb-4" id="FileUploadSuccess">
                  { !loading && !genFileURL &&
                    <h3>Please upload PDF to create Client Presentation</h3>
                  }
                  { loading &&
                    <h3><img src={'/assets/images/Loader.gif'} alt="Loading..." width="50" height="=50" /> Parsing!, please wait.Generation of ppt time depends on the pdf size</h3>
                  }
              </div>
              <div className="card-header mb-4" id="divFilesWebViewer">
                <>
                  {
                    genFileURL ? (
                    <>
                      <a className="btn btn-success btn-success-href" href={genFileURL}>
                        Download
                      </a>
                      <button className="btn btn-success btn-success-href ml-3" onClick={handleViewClick}>
                        View
                      </button>
                    </>
                    ) : null
                  }
                </>                             

                {/* <FileWebViewer/> */}
              </div>                  
            </div>
        </div>
      </div>    
    </>
  )
};

export default StepNine;