import React, { useEffect, useState, useRef } from 'react';


const FileWebViewer = ({ url }) => {
  //console.log("FileWebViewer-->",url)
  //const url4 = "https://cpb.yourcloudnetwork.net/GOODWILL_OF_NORTH_GEORGIA_INC.pptx";

  return (
    <>      
      <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
          width="100%"
          height="600px"
          frameBorder="0"
      >
      </iframe>
    </>    
  );
};

export default FileWebViewer;