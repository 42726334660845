import React from 'react';

const ClientDetails = ({ prevStep, nextStep, handleChange, values }) => {
  return (
    <>
      <div className="row">
        <div className='col-12'>
          <h4>Client Details</h4>
        </div>
        <div className="col-lg-12">            
          <div className="php-email-form">
              <div className="row gy-4">
                <div className="col-md-6">
                <label className='font-weight-bold'>First Name:</label>
                  <input type="text" className="form-control" placeholder="First Name" onChange={handleChange('cfirstname')} value={values.cfirstname} />
                </div>
                <div className="col-md-6 ">
                <label className='font-weight-bold'>Last Name:</label>
                  <input type="text" className="form-control" placeholder="Last Name" onChange={handleChange('clastname')} value={values.clastname} />
                </div>
                <div className="col-md-6">
                <label className='font-weight-bold'>Email:</label>

                  <input type="text" className="form-control" placeholder="Email" onChange={handleChange('cemail')} value={values.cemail} autoComplete="Email" />
                </div>
                <div className="col-md-6">
                <label className='font-weight-bold'>Mobile:</label>

                  <input type="text" className="form-control" placeholder="Mobile" onChange={handleChange('cmobile')} value={values.cmobile} autoComplete="Mobile" />
                </div>                
                <div className="col-md-6">
                <label className='font-weight-bold'>Client Logo:</label>
                  <input type="text" className="form-control" placeholder="Client Logo URL" onChange={handleChange('clogourl')} value={values.clogourl} autoComplete="Cleint Logo" />
                  <p className="mb-1 mt-1">Or</p>
                  <label className='btn btn-info d-block'>
                    Upload Client Logo From Local
                    <input type="file" className="form-control" accept='image/jpg, image/jpeg, image/png' onChange={handleChange('clogo')} style={{display:'none'}}/>
                  </label>
                  {values.logoPreview && <img src={values.logoPreview} alt="preview" style={{width:'135px'}} />}
                </div>
                <div className="col-md-6 ">
                <label className='font-weight-bold'>Industry:</label>
                  <select className='form-control' onChange={handleChange('cindustry')} value={values.cindustry}>
                    <option value={''}>Select One</option>
                    <option value={'private-company'}>Private Company</option>
                    <option value={'public-traded'}>Public Traded</option>
                  </select>
                </div>
                <div className="col-md-6 ">
                  <label className='font-weight-bold'>Credit/Loan Client:</label>
                  <select className='form-control' onChange={handleChange('ccreditstate')} value={values.ccreditstate}>
                    <option value={'yes'}>Yes</option>
                    <option value={'no'}>No</option>
                  </select>
                </div>
                <div className="col-md-6 ">
                  <label className='font-weight-bold'>Annual Revenue:</label>
                  <input type="number" className="form-control" placeholder="Annual Revenue" onChange={handleChange('canualrevenue')} value={values.canualrevenue} />
                </div>
              </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default ClientDetails