import React, { useEffect, useState } from 'react';
import NumberSpan from '../../NumberSpan';
import { CommonService } from '../../../services';

const LiquidityRatio = ({values, onWCDataCalculated}) => {
    const [liquidityRatio, setLiquidityRatio] = useState({});
    useEffect(() => {
        // values = {
        //     totalSaleRevenue: 84000,
        //     cogs: 74000,
        //     accountReceivables: 81000,
        //     accountPayables: 75000,
        //     loanIntRate: 6.5,
        //     investmentIntRate: 4.5,
        //     creditRate: 1.5,
        //     inventory: 78000,
        //     currentAssets: 159000,
        //     currentLiabilities: 75000,
        //     totalAssets: 159000
        // };
        setLiquidityRatio(values);

        const currentRatio = new Map();
        currentRatio.set("currentAssets", parseFloat(values.currentAssets || 0) );
        currentRatio.set("currentLiabilities", parseFloat(values.currentLiabilities || 0) );
        currentRatio.set("currentRatio", CommonService.roundNum((values.currentAssets / values.currentLiabilities) || 0) );
        const assetsTurnoverRatio = new Map();
        assetsTurnoverRatio.set("totalSaleRevenue", parseFloat(values.totalSaleRevenue || 0));
        assetsTurnoverRatio.set("totalAssets", parseFloat(values.totalAssets || 0));
        assetsTurnoverRatio.set("assetsTurnoverRatio", CommonService.roundNum((values.totalSaleRevenue / values.totalAssets) || 0));
        const quickRatio = new Map();
        quickRatio.set("currentAssets", parseFloat(values.currentAssets || 0) );
        quickRatio.set("currentLiabilities", parseFloat(values.currentLiabilities || 0) );
        quickRatio.set("quickRatio", (values.currentAssets && values.currentLiabilities && values.inventory ? CommonService.roundNum((parseFloat(values.currentAssets) - parseFloat(values.inventory)) / parseFloat(values.currentLiabilities)) : 0) );
        onWCDataCalculated({
            assetsTurnoverRatio,
            currentRatio,
            quickRatio
        }, 'liquidityRatioCalculated');

    }, []);

    return <>
        <div className='row'>
            <div className='col-12 text-center'>
                <h4 className=''>Liquidity Ratio</h4>
            </div>
        </div>
        <div className='row justify-content-between'>
            <div className='col-lg-4 col-md-6 col-12'>
                <div className='bg-custom-lgreen p-1 text-light text-center'>
                    <h5>Current Ratio</h5>
                </div>
                <div style={{border: '1px solid'}} className='p-1 wc-card'>
                    <p className='d-inline-block'><b>Current Assets:</b> <NumberSpan number={liquidityRatio.currentAssets} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>Current Liabilities:</b> <NumberSpan number={liquidityRatio.currentLiabilities} money={true} /></p> = <NumberSpan number={liquidityRatio.currentAssets / liquidityRatio.currentLiabilities} round={true} />
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
                <div className='bg-custom-lgreen p-1 text-light text-center'>
                    <h5>Quick Ratio</h5>
                </div>
                <div style={{border: '1px solid'}} className='p-1 wc-card'>
                    (<p className='d-inline-block'><b>Current Assets:</b> <NumberSpan number={liquidityRatio.currentAssets} money={true} /></p> -&nbsp;
                    <p className='d-inline-block'><b>Inventory:</b> <NumberSpan number={liquidityRatio.inventory} money={true} /></p>) /&nbsp;
                    <p className='d-inline-block'><b>Current Liabilities:</b> <NumberSpan number={liquidityRatio.currentLiabilities} money={true} /></p> = <NumberSpan number={(liquidityRatio.currentAssets - liquidityRatio.inventory) / liquidityRatio.currentLiabilities} round={true}/>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
                <div className='bg-custom-lgreen p-1 text-light text-center'>
                    <h5>Assets Turnover Ratio</h5>
                </div>
                <div style={{border: '1px solid'}} className='p-1 wc-card'>
                    <p className='d-inline-block'><b>Sales:</b> <NumberSpan number={liquidityRatio.totalSaleRevenue} money={true} /></p> /&nbsp;
                    <p className='d-inline-block'><b>Total Assets:</b> <NumberSpan number={liquidityRatio.totalAssets} money={true} /></p> = <NumberSpan number={liquidityRatio.totalSaleRevenue / liquidityRatio.totalAssets} round={true} />
                </div>
            </div>
        </div>
    </>
}

export default LiquidityRatio;