const NumberSpan = ({number, money, round, className}) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: "USD",
      });
      const roundNum = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
      }
    return <span className={'text-primary ' + className}>{money ? formatter.format(number || 0) || 0 : (round ? roundNum(number || 0) || 0 : number || 0)}</span>
};

export default NumberSpan;