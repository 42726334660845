import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
//import AppNavbar from '../AppNavbar';
import MultiStepForm from '../../components/GeneratePowerpoint/index';
import { BASE_API_URL } from "../../config";
import { CommonService } from '../../services';

let uploadedFileName;
let uploadedFileDownloadURL;
let uploadedFileType;
let processedFileName;
let mappingFileName;
let mappingFileURL;
let processedFileURL;

const GeneratePowerpoint = () => {
	const [authenticated, setAuthenticated] = useState(false);
	const [file, setFile] = useState(null);
	const [file1, setFile1] = useState(null);
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);
	const history = useNavigate();
	let dataFetchedRef = useRef(false);

	useEffect(() => {
		if(user == null) {
			setLoading(true);
			if (dataFetchedRef.current) return;
			dataFetchedRef.current = true;
	
			fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
				.then(response => response.text())
				.then(body => {
					if (body === '') {
						setAuthenticated(false);
						console.log("User not authenticated, redirecting the user to homepage");
						history('/');
					} else {
						if(CommonService.isJsonParsable(body)) {
							setUser(JSON.parse(body));
							setAuthenticated(true);
						} else {
							setAuthenticated(false);
							console.log("User not authenticated, redirecting the user to homepage");
							history('/');
						}
					}
					setLoading(false);
				});
		}
	}, []);
	return (
		user && <MultiStepForm tuser={user} />
	)
};

export default GeneratePowerpoint;