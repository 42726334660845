import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button, ButtonGroup, Table } from '@material-ui/core';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { BASE_API_URL } from "../../config";

const StepFive = ({ prevStep, nextStep, handleSelectedBankers, addBankerToState, removeBanker, values }) => {

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);

  const Continue = e => {
    e.preventDefault();

    // if(values.bankers.length === 0) {
    //     NotificationManager.error("Please select min 1 & max 6", "Error");
    // } else {
    //   handleSelectedBankers(Array.from(values.bankers));
    //   nextStep();
    // }

    handleSelectedBankers(Array.from(values.bankers));
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  const addBanker = (group) => {
    console.log(values.bankers, "BANKERSS");
    if(values.bankers.size >= 6) {
      alert("Maximum 6 bankers allowed.");
      return;
    }
    // values.bankers.add(group);
    addBankerToState(group)
    alert("Banker :" + group.firstName + group.lastName + " Sucessfully added to the list");
  }

  useEffect(() => {
    setLoading(true);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/bankers`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        setGroups(data);
        setLoading(false);
      }).catch(function (error) {
        console.log("Error :" + error);
      })
  }, []);

  let rowcount = 0;
  const groupList = groups.map(group => {
    let clr = "#d1e0e6";
    rowcount++;
    if (rowcount % 2 == 0) {
      clr = "white"
    }

    return (
      <tr key={group.id}>
        <th scope="row">{group.firstName} {group.lastName}</th>
        <td>{group.email}</td>
        <td>{group.title}</td>
        <td>{group.department}</td>
        <td className="text-center">
          { values.bankersId.includes(group.id) ?
            <button type="button" className="btn btn-danger text-center" onClick={() => removeBanker(group)}>Remove</button>
            :
            <button type="button" className="btn btn-success text-center" onClick={() => addBanker(group)}>Select</button>
          }
        </td>
      </tr>
    )
  });


  return (
    <>
      <div id="content" className="p-2">
        <div className="form-submission">
            <div className="col-lg-12">
              <div className="card-header mb-4">
                  <h4 className="card-title">Generate Presentation</h4>
                  <h3>Choose Relationship team contacts, Limit to 6</h3>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                    <div className="php-email-form">
                      <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Title</th>
                              <th scope="col">Department</th>
                              <th scope="col" className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                              {groupList}                        
                          </tbody>
                      </table>
                      <p className="p-button-holder">
                        <input type="button" className="btn btn-success-custom previous mr-3 mt-3 text-center" value="Previous" onClick={Previous} />
                        <input type="button" className="btn btn-success-custom next  mt-3 text-center" value="Next" onClick={Continue} />
                      </p>
                    </div>
                    <img src="/assets/images/RelationShipTeam.jpg" alt="" title="" className="mt-3 img-fluid" />
                  </div>
              </div>
            </div>
        </div>
      </div> 

      <NotificationContainer id="stepFiveError" />   
    </>
  );
}


export default StepFive