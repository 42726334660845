import React, { useEffect, useState, useRef } from 'react';
import '../App.css';
import AppNavbar from './AppNavbar';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { BASE_API_URL, BASE_API_PORT, BASE_WEB_ROOT } from "../config";

const Default = () => {
    const [isOpen, setIsOpen] = useState(false);  
    const [cookies] = useCookies(['XSRF-TOKEN']);  
    const [loading, setLoading] = useState(false);  
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(undefined);
    const [auth, setAuth] = useState(false);
    const [isAdminUser, setAdminUser] = useState(false);
    const [isRMUser, setRMUser] = useState(false);
    const [isEveryOneElse, setEveryOneElse] = useState(false);
    const [count, setCount] = useState(0);
    let dataFetchedRef = useRef(false);
    let authenticatedUSer = null;
    const history = useNavigate();

    useEffect(() => {	 
        setLoading(true); 
        if (dataFetchedRef.current) {
            return;
        } else {
            dataFetchedRef.current = true;
            setCount(count + 1);
        }

        fetch(`${BASE_API_URL}/api/user`, { credentials: 'include'})
            .then(response => response.text())
            .then(body => {
            if (body === '') {
                setAuthenticated(false);
                setAuth(false);
                console.log("User not authenticated, redirecting the user to homepage");
                history('/');
            } else {		          
                setUser(JSON.parse(body));
                authenticatedUSer = JSON.parse(body);
                setAuthenticated(true);
                if(authenticatedUSer.groups.includes('admins')){						
                    setAdminUser(true);
                }
                if(authenticatedUSer.groups.includes('rusers')){						
                    setRMUser(true);
                }
                if(authenticatedUSer.groups.includes('Everyone')){						
                    setEveryOneElse(true);
                }
                setAuth(true);
            }
            setLoading(false); 
                dataFetchedRef.current = true;		         
            });      
    }, [])

    useEffect(() => {
        setLoading(true);
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
            .then(response => response.text())
            .then(body => {
            console.log(body);
            if (body === '') {
                setAuthenticated(false);
            } else {
                setUser(JSON.parse(body));
                setAuthenticated(true);
            }
            setLoading(false);
            });
    }, [setAuthenticated, setLoading, setUser])

    useEffect(() => {
        if (user !== undefined) {
            window.location.href = '/dashboard';
        }
        /*else
        {
            let port = (window.location.port ? ':' + window.location.port : '');
            port = ':8080';
            window.location.href = `//${window.location.hostname}${port}/private`;   
        }*/
    }, [user]) /**/
   
    const login = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        // if (port === ':3000') {
            port = ':'+BASE_API_PORT; //port = ':8080';
        // }
        //window.location.href =  `//${window.location.hostname}${port}/private`;
        window.location.href =  `${BASE_API_URL}/private`;
    }

    /*
    const logout = () => {
        fetch(`${BASE_API_URL}/api/logout`, {
        method: 'POST', credentials: 'include',
        headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] }
        })
        .then(res => res.json())
        .then(response => {
        window.location.href = `${response.logoutUrl}?id_token_hint=${response.idToken}`
            + `&post_logout_redirect_uri=${window.location.origin}`;
        });
    }
    */

    if (loading) {
        return <p>Loading...</p>;
    }


    return (
        <>
            {
                authenticated ?
                    null
                    :
                    <div className="login-form">
                        <div className="container h-100 justify-content-center d-flex align-items-center">
                            <div className="row">
                                <div className="form-input">
                                    <h3 className="text-center mb-3">Lenitt Technology</h3>
                                    <p className="text-center">Please log in to manage Treasury and Liquidity Services</p>            
                                    <div className="login-btn">
                                        <input type="button" value="Login" className="login" onClick={login} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default Default;