import React, { useEffect, useState, useRef } from 'react';
import { BASE_API_URL, BASE_SAAS_API_URL } from "../../config";
import axios from 'axios';


const ProcessedFilesList = () => {
    const [loading, setLoading] = useState(false);
    const dataFetchedRef = useRef(false);
    const [groups, setGroups] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [type, setType] = useState('analysis');

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getRunHistory();
    }, []);

    const getRunHistory = pptStatementType => {
        setLoading(true);
        if(!pptStatementType) {
            pptStatementType = type;
        }
        fetch(`${BASE_SAAS_API_URL}/parser-history?type=${pptStatementType}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => {
            setGroups(data.data.runhistoryList);
            setPagination(data.data.pagination);
            setLoading(false);
        }).catch(function (error) {
            console.log("Error :" + error);
        })
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    const groupList = groups.map(group => {
        // return <tr key={group.tmServiceId}>
        return  <tr key={group._id}>
            {/* <td scope="row"><b>{ group.fileName }</b></td> */}
            <td scope="row"><b>{ group.client_name }</b></td>
            {/* <td className="text-left"><a href={group.url}>Click here to download</a></td> */}
            <td className="text-left">
                {
                    Array.isArray(group.ppt_file) ? 
                    group.ppt_file[0] && <><a href={group.ppt_file[0].url}>Click here to download</a><br/>{group.ppt_file.length > 1 && <span>{group.ppt_file.length - 1} more..</span> }</>
                    :
                    <a href={group.ppt_file}>Click here to download</a>
                }
            </td>
            {/* <td className="text-center"><button type="button" className="btn btn-success text-center" onClick={() => remove(group.fileName)}>Delete</button></td> */}
            <td className="text-center"><button type="button" className="btn btn-danger text-center" onClick={() => deleteRunHistory(group._id)}>Delete</button></td>

        </tr>
    });

    const deleteRunHistory = async id => {
        try {
            const response = await axios.delete(`${BASE_SAAS_API_URL}/run-history`, {
                data: {id:id}
            });
            // alert
            alert("Deleted success");
            const newRunHistoryList = groups.filter(g => g._id !== id);
            setGroups(newRunHistoryList);
        } catch(e) {
            // show toast
        }
    }

    const remove = async (name) => {
        let response = await fetch(BASE_API_URL + '/api/processedfiles/' + name, {
            method: 'DELETE',
            credentials: 'include'
        });

        if (response.status == 403) {
            alert("Not authorized to delete a file!!!!");
        }

        if (response.status === 204) {
            alert("Deleted the file " + name + " sucessfully");
            window.location.reload();
        }

        if (response.status != 200) {
            console.log("Error: " + response.message);
        }
    }
    const typeChange = e => {
        const type = e.target.value;
        console.log(type, "TYPE");
        setType(type);
        getRunHistory(type); 
    }
    return (
        <div id="content" className="p-2">
            <div className="form-submission">
                <div className="col-lg-12">
                    <div className="card-header mb-4">
                        <h3>Parsing History</h3>
                    </div>
                    <div className="row">
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-4'>
                                    <select className='form-controle' value={type} onChange={typeChange}>
                                        <option value={'analysis'}>Analysis</option>
                                        <option value={'working-capital'}>Working Capital</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                        <table className="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th width="26%" scope="col">File Name</th>
                                    <th width="50%" scope="col">URL</th>
                                    <th width="24%" scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { groupList }                 
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProcessedFilesList;