import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { BASE_API_URL } from "../../config";
//import logo from '/assets/images/logo.png';
//import download from '/assets/images/download.jpg';


//const Header = () => {
const Header = ({ toggleSidebar }) => {
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [fullName, setFullName] = useState(false);
    const [isOpen, setIsOpen] = useState(false);  
    const [cookies] = useCookies(['XSRF-TOKEN']);  
    const [loading, setLoading] = useState(false);  
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(undefined);
    const [auth, setAuth] = useState(false);
    const [isAdminUser, setAdminUser] = useState(false);
    const [isRMUser, setRMUser] = useState(false);
    const [isEveryOneElse, setEveryOneElse] = useState(false);
    const [count, setCount] = useState(0);
    let dataFetchedRef = useRef(false);
    let authenticatedUSer = null;
    const history = useNavigate();

    useEffect(() => {
        setLoading(true); 
        if (dataFetchedRef.current) {
            return;
        } else {
            dataFetchedRef.current = true;
            setCount(count + 1);
        }        
        //console.log(count, "COUNT")
           
        fetch(`${BASE_API_URL}/api/user`, { credentials: 'include'})
        .then(response => response.text())
        .then(body => {
            if (body === '') 
            {
                setAuthenticated(false);
                setAuth(false);
                console.log("User not authenticated, redirecting the user to homepage");
                history('/');
            } 
            else 
            {		          
                setUser(JSON.parse(body));
                authenticatedUSer = JSON.parse(body); // console.log(authenticatedUSer, "USERSSS");
                setAuthenticated(true);
                if(authenticatedUSer.groups.includes('admins')){						
                    setAdminUser(true);
                }
                if(authenticatedUSer.groups.includes('rusers')){						
                    setRMUser(true);
                }
                if(authenticatedUSer.groups.includes('Everyone')){						
                    setEveryOneElse(true);
                }
                setAuth(true);
                setFullName(authenticatedUSer.given_name +" "+ authenticatedUSer.family_name);
                //console.log("Header",authenticatedUSer)
            }
            setLoading(false); 
            dataFetchedRef.current = true;                
        });
    }, []);

    const handleSubMenuOpen = () => {
        setSubMenuOpen(!subMenuOpen);
    };

    const handleLogout = () => {
        fetch(`${BASE_API_URL}/api/logout`, {
            method: 'POST', credentials: 'include',
            headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] }
        })
            .then(res => res.json())
            .then(response => {
                window.location.href = `${response.logoutUrl}?id_token_hint=${response.idToken}`
                    + `&post_logout_redirect_uri=${window.location.origin}`;
            });
    }

    const handleSidebarToggle = () => {
        toggleSidebar();
    };

    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <button type="button" id="sidebarCollapse" onClick={handleSidebarToggle} className="btn btn-primary col-1"> <i className="fa fa-bars"></i> <span className="sr-only">Toggle Menu</span> </button>
                    <div className="logo col-10"><img src="/assets/images/logo.png" className="logo"  /></div>
                    <div className="profile col-2">
                        <div className="dropdown">
                            <button onClick={handleSubMenuOpen} className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img src="/assets/images/download.jpg" className="profile" /></button>
                            <div className="dropdown-menu" style={{ display: subMenuOpen ? 'block' : 'none' }} aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" href="#">{ authenticated ? fullName : null }</a>
                                <a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
};

export default Header;