import React from "react";

const Dashboard = () => {
    return (
        <React.Fragment> 
            <p>Dashboard</p>           
        </React.Fragment>
    );
};

export default Dashboard;