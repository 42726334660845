import React from 'react';
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";
// import {SlideshowLightbox} from 'lightbox.js-react'
import Viewer from 'react-viewer';

const ImageView = ({open, src, onClose}) => {
    
    // return open && <Lightbox mainSrc={src} onCloseRequest={() => onClose()} animationDisabled={false} />
    return <Viewer visible={open}
    onClose={() => { onClose(); } }
    images={[{src: src, alt: 'preview'}]} />
};

export default ImageView;