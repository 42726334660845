import React from "react";
import Validator from "../../Validator";
import './input.css';

const WorkingCapitalInput = ({
  handleChange,
  values,
  acceptNumber
}) => {
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h4>Financial Statement Data</h4>
        </div>
        <div className="col-4">
          <h4>Financial Metrics</h4>
        </div>
        <div className="col-lg-8">
          <form action="#" method="post" className="php-email-form">
            <div className="row gy-4">
              <div className="col-md-6">
                <label className='font-weight-bold'>Company Name:</label>
                <input
                  type="text"
                  className={"form-control " + (values.companyError ? 'is-invalid' : '')}
                  placeholder="Company Name"
                  onChange={handleChange("company")}
                  value={values.company}
                  autoComplete="Company"
                  required={true}
                />
                <Validator error={values.companyError} />
              </div>
              <div className="col-md-6 ">
              <label className='font-weight-bold'>Total Sale/Revenue:</label>
                <input
                  type="text"
                  className={"form-control " + (values.totalSaleRevenueError ? 'is-invalid' : '')}
                  placeholder="Total Sale/Revenue"
                  value={values.totalSaleRevenue}
                  autoComplete="Total Sale/Revenue"
                  required={true}
                  onChange={handleChange("totalSaleRevenue")}
                />
                <Validator error={values.totalSaleRevenueError} />
              </div>
              <div className="col-md-6 ">
              <label className='font-weight-bold'>Cost of Good Sold:</label>
                <input
                  type="text"
                  className={"form-control " + (values.cogsError ? 'is-invalid' : '')}
                  placeholder="Cost of Good Sold"
                  value={values.cogs}
                  autoComplete="Cost of Good Sold"
                  required={true}
                  onChange={handleChange("cogs")}
                />
                <Validator error={values.cogsError} />
              </div>
              <div className="col-md-6">
              <label className='font-weight-bold'>Account Receivables:</label>
                <input
                  type="text"
                  className={"form-control " + (values.accountReceivablesError ? 'is-invalid' : '')}
                  placeholder="Account Receivables"
                  value={values.accountReceivables}
                  autoComplete="Account Receivables"
                  required={true}
                  onChange={handleChange("accountReceivables")}
                />
                <Validator error={values.accountReceivablesError} />
              </div>
              <div className="col-md-6">
              <label className='font-weight-bold'>Account Payables:</label>
                <input
                  type="text"
                  className={"form-control " + (values.accountPayablesError ? 'is-invalid' : '')}
                  placeholder="Account Payables"
                  value={values.accountPayables}
                  autoComplete="Account Payables"
                  required={true}
                  onChange={handleChange("accountPayables")}
                />
                <Validator error={values.accountPayablesError} />
              </div>
              <div className="col-md-6">
              <label className='font-weight-bold'>Loan Int Rate:</label>
                <input
                  type="text"
                  className={"form-control " + (values.loanIntRateError ? 'is-invalid' : '')}
                  placeholder="Loan Int Rate"
                  value={values.loanIntRate}
                  autoComplete="Loan Int Rate"
                  onChange={handleChange("loanIntRate")}
                />
                <Validator error={values.loanIntRateError} />
              </div>
              <div className="col-md-6 ">
              <label className='font-weight-bold'>Inventory:</label>
                <input
                  type="text"
                  className={"form-control " + (values.inventoryError ? 'is-invalid' : '')}
                  placeholder="Inventory"
                  value={values.inventory}
                  autoComplete="Inventory"
                  onChange={handleChange("inventory")}
                />
                <Validator error={values.inventoryError} />
              </div>
              <div className="col-md-6 ">
              <label className='font-weight-bold'>Investment Int Rate:</label>
                <input
                  type="text"
                  className={"form-control " + (values.investmentIntRateError ? 'is-invalid' : '')}
                  placeholder="Investment Int Rate"
                  value={values.investmentIntRate}
                  autoComplete="Investment Int Rate"
                  onChange={handleChange("investmentIntRate")}
                />
                <Validator error={values.investmentIntRateError} />
              </div>

              <div className="col-md-6 ">
              <label className='font-weight-bold'>Earning Credit Rate:</label>
                <input
                  type="text"
                  className={"form-control " + (values.creditRateError ? 'is-invalid' : '')}
                  placeholder="Earning Credit Rate"
                  value={values.creditRate}
                  onChange={handleChange("creditRate")}
                />
                <Validator error={values.creditRateError} />
              </div>

              <div className="col-md-6 ">
              <label className='font-weight-bold'>Outstanding Loan Balance:</label>
                <input
                  type="text"
                  className={"form-control " + (values.loanBalanceError ? 'is-invalid' : '')}
                  placeholder="Outstanding Loan Balance"
                  value={values.loanBalance}
                  onChange={handleChange("loanBalance")}
                />
                <Validator error={values.loanBalanceError} />
              </div>

              {/** Liquidity Ratio */}
              <div className="col-md-6 ">
              <label className='font-weight-bold'>Current Assets:</label>
                <input
                  type="text"
                  className={"form-control " + (values.currentAssetsError ? 'is-invalid' : '')}
                  placeholder="Current Assets"
                  value={values.currentAssets}
                  onChange={handleChange("currentAssets")}
                />
                <Validator error={values.currentAssetsError} />
              </div>

              <div className="col-md-6 ">
              <label className='font-weight-bold'>Current Liabilities:</label>
                <input
                  type="text"
                  className={"form-control " + (values.currentLiabilitiesError ? 'is-invalid' : '')}
                  placeholder="Current Liabilities"
                  value={values.currentLiabilities}
                  onChange={handleChange("currentLiabilities")}
                />
                <Validator error={values.currentLiabilitiesError} />
              </div>

              <div className="col-md-6 ">
              <label className='font-weight-bold'>Total Assets:</label>
                <input
                  type="text"
                  className={"form-control " + (values.totalAssetsError ? 'is-invalid' : '')}
                  placeholder="Total Assets"
                  value={values.totalAssets}
                  onChange={handleChange("totalAssets")}
                />
                <Validator error={values.totalAssetsError} />
              </div>
             
            </div>
          </form>
        </div>
        <div className="col-lg-4">
          <div className="row financial-metrics">
            <div className="col-12">
                <div className={"mcontainer " + (values.accountReceivables && values.totalSaleRevenue && 'enable')}>
                  <div className="icon-check">
                    <span className="checkmark"></span> 
                  </div>
                  <div>
                    <p>Days Receivable</p>
                    <p>Effectiveness of collecting revenue.</p>
                  </div>
                </div>
            </div>
            <div className="col-12">
              <div className={"mcontainer " + (values.accountPayables && values.cogs && 'enable')}>
                    <div className="icon-check">
                      <span className="checkmark"></span> 
                    </div>
                    <div>
                      <p>Days Payable</p>
                      <p>Effectiveness paying others</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
            <div className={"mcontainer " + (values.inventory && values.cogs && 'enable')}>
                    <div className="icon-check">
                      <span className="checkmark"></span> 
                    </div>
                    <div>
                      <p>Days Inventory</p>
                      <p>Average time to turn inventory into sales.</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
            <div className={"mcontainer " + (values.accountReceivables && values.totalSaleRevenue && 'enable')}>
                    <div className="icon-check">
                      <span className="checkmark"></span> 
                    </div>
                    <div>
                      <p>Sales to working capital ratio</p>
                      <p>Sales generated from each doller of working capital.</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
            <div className={"mcontainer " + (values.currentAssets && values.currentLiabilities && values.inventory && 'enable')}>
                    <div className="icon-check">
                      <span className="checkmark"></span> 
                    </div>
                    <div>
                      <p>Quick Ratio</p>
                      <p>Ability to repay creditors.</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
            <div className={"mcontainer " + (values.currentAssets && values.currentLiabilities && 'enable')}>
                    <div className="icon-check">
                      <span className="checkmark"></span> 
                    </div>
                    <div>
                      <p>Current Ratio</p>
                      <p>Company liquidity</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
            <div className={"mcontainer " + (values.accountReceivables && values.totalSaleRevenue && values.accountPayables && values.cogs && 'enable')}>
                    <div className="icon-check">
                      <span className="checkmark"></span> 
                    </div>
                    <div>
                      <p>Working capital details</p>
                      <p>Working capital details</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkingCapitalInput;
