import React, { useEffect, useRef, useState } from "react";
import WCStep from "../../../components/FinancialAnalysis/WorkingCapital";
import { BASE_API_URL } from "../../../config";
import {  useNavigate } from "react-router-dom";
import { CommonService } from "../../../services";

const WorkingCapital = ({}) => {

    const [user, setUser] = useState(null);
    let dataFetchedRef = useRef(false);
    const history = useNavigate();
    useEffect(() => {
        if (dataFetchedRef.current) return;

        dataFetchedRef.current = true;
        fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
            .then(response => response.text())
            .then(body => {
                if (body === '') {
                    console.log("User not authenticated, redirecting the user to homepage");
                    history('/');
                } else {
                    if(CommonService.isJsonParsable(body)) {
                        setUser(JSON.parse(body));
                        history(true);
                    } else {
                        console.log("User not authenticated, redirecting the user to homepage");
                        history('/');
                    }
                }
            });
    }, []);

    return user && <WCStep user={user} />;
};

export default WorkingCapital;