import React from "react";
import { Route, Redirect } from "react-router-dom";
//import {LoginCallback} from '@okta/okta-react'


/* Newly Created Pages */
import Default from '../pages/Default';        
import Dashboard from "../pages/Dashboard/index";
import GeneratePowerpoint from "../pages/GeneratePowerpoint/index";
import RunHistory from "../pages/RunHistory/index";
import BankersList from '../pages/Bankers/index';
import BankersAddEdit from '../pages/Bankers/AddEdit';
import BankersImport from '../pages/Bankers/Import';
import BankersActivate from '../pages/Bankers/Activate';
import WorkingCapital from "../pages/FinancialAnalysis/WorkingCapital";
/* Newly Created Pages */


/* OLD Pages */
import Home from '../pages/Home';
import AAstmt from '../pages/AAstmt';
import ProcessedFilesList from '../pages/ProcessedFilesList'
import BankerList from '../pages/BankerList';
import BankerEdit from '../pages/BankerEdit';
import BankerImport from '../pages/BankerImport';
import BankerActivate from '../pages/BankerActivate';
/* OLD Pages */


import TMServiceList from '../pages/TMServiceList';
import TMServiceEdit from '../pages/TMServiceEdit';
import RecommendationList from '../pages/RecommendationList'
import RecommendationEdit from '../pages/RecommendationEdit'
import RecommendationAdd from '../pages/RecommendationAdd'
import ParserConfig from '../pages/ParserConfig';
import SynonymEdit from '../pages/SynonymEdit';
import SynonymAdd from '../pages/SynonymAdd';



const authProtectedRoutes = [
    /* Newly Created */
    { path: "/", exact: true, component: Default, layout: false },
    //{ path: "/login/callback", component: LoginCallback },
    //{ path: "/", exact: true, component: () => <Redirect to="/login/callback" /> },
    { path: "/dashboard", exact: true, component: Dashboard },                
    { path: "/generate/powerpoint", exact:true, component: GeneratePowerpoint },                
    { path: "/run/history", exact:true, component: RunHistory },
    { path: "/banker/list", exact:true, component: BankersList },
    { path: "/banker/:param", exact:true, component: BankersAddEdit },
    { path: "/banker/activate/:id", exact:true, component: BankersActivate },
    { path: "/banker/import", exact:true, component: BankersImport },
    { path: "/financial-analysis/working-capital", exact:true, component: WorkingCapital },
    /* Newly Created */


    /* OLD */
    //{ path: "/", exact: true, component: Home, layout: false },
    { path: "/bankers/aastmt", exact:true, component: AAstmt },
    { path: "/bankers/pfiles", exact:true, component: ProcessedFilesList },	
    { path: "/bankers", exact:true, component: BankerList },
    { path: "/bankers/:id", exact:true, component: BankerEdit },
    { path: "/bankers/import", exact:true, component: BankerImport },
    { path: "/activate/:id", exact:true, component: BankerActivate }, 
    { path: "/bankers/tmservice", exact:true, component: TMServiceList },  
    { path: "/bankers/pmapping", exact:true, component: ParserConfig },    
    /* OLD */
    
    
    { path: "/recommend/new", exact:true, component: TMServiceEdit },
    { path: "/recommend/mapping", exact:true, component: RecommendationList },
    { path: "/recommendation/edit/:id", exact:true, component: RecommendationEdit },
    { path: "/recommendation/add/:id", exact:true, component: RecommendationAdd },
    { path: "/synonym/edit/:id/:name", exact:true, component: SynonymEdit },
    { path: "/synonym/add/:id/:name/:aname", exact:true, component: SynonymAdd },    
];

const publicRoutes = [
];

export { authProtectedRoutes, publicRoutes };