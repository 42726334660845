import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import AppNavbar from '../AppNavbar';
import { BASE_API_URL } from "../../config";


const BankersImport = () => {
    const [file, setFile] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadFileState, setUploadFileState] = useState('<h3>Please upload a file</h3>');
    const history = useNavigate();
    let dataFetchedRef = useRef(false);
    const notAuthorized = <h3>Not Authorized!!!</h3>;

    useEffect(() => {
        setLoading(true);
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
        .then(response => response.text())
        .then(body => {
            if(body === '') {
                setAuthenticated(false);
                console.log("User not authenticated, redirecting the user to homepage");
                history('/');
            } else {
                setAuthenticated(true);
            }
            setLoading(false);
        });
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        //var fileUploadSuccess = document.querySelector('#FileUploadSuccess');
        //fileUploadSuccess.innerHTML = "<h3>Parsing!!!!, please wait........</h3>";
        setUploadFileState('<h3>Parsing!!!!, please wait........</h3>');
        let res = await uploadFile(file);
        console.log(res);
        //fileUploadSuccess.innerHTML = "<li>Uploaded File Name : " + res.fileName +"</li>" +
        //"<li>Processed file download URL :  " + res.fileDownloadUri +"</li>";
        setUploadFileState(`<li>Uploaded File Name : ${res.fileName}</li><li>Processed file download URL : ${res.fileDownloadUri}</li>`);
    };   

    const uploadFile = async file => {
        const formData = new FormData();
        formData.append("file", file);
        console.log("File Name:" + file);
        // var fileUploadSuccess = document.querySelector('#FileUploadSuccess');
        // send fetch along with cookies
        let response = await fetch(`${BASE_API_URL}/api/excel/uploadFile`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        });

        if (response.status === 403) {
            setUploadFileState("<h1>Not Authorized!!!!<h1>");
        }

        // server responded with http response != 200
        if (response.status !== 200)
            throw new Error('HTTP response code != 200');
        // read json response from server
        // success response example : {"error":0,"message":""}
        // error response example : {"error":1,"message":"File type not allowed"}
        let json_response = await response.json();

        if (json_response.error === 1)
            throw new Error(json_response.message);

        return json_response;
    };

    const handleOnChange = e => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
    };

    return (
        <>
            <div id="content" className="p-2">
                <div className="form-submission">
                    <div className="col-lg-12">
                    <div className="card-header mb-4">
                        <h3>Bulk upload bankers</h3>
                    </div>
                    <div className="row">
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div>
                            <div className="mb-3"><label htmlFor="file" className="form-label">Uplodad an excel file</label>
                            <input name="file" required type="file" className="file-input form-control" onChange={handleOnChange} />                        
                            </div>
                            <p>    
                            <button type="submit" className="btn btn-success-custom mr-4 text-center">Upload</button>
                            <a className="btn btn-success-custom text-center" tag={Link} href="/banker/list">Cancel</a>
                            </p>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                <br />
                <div className="form-submission">
                    <div className="col-lg-12">
                        {/* <div className="card-header mb-4" id="FileUploadSuccess" dangerouslySetInnerHTML={{ __html: uploadFileState }}> */}
                        <div className="card-header mb-4" id="FileUploadSuccess">
                            <h3>Please upload a file to see the response</h3>
                        </div>                 
                    </div>
                </div>
            </div>          
        </>
    )
};

export default BankersImport;